const img1 = require("../images/slider/mainimg.png")
// const img1 = require("../images/slider/data101_introduce_compress.mp4")
const img2 = require("../images/slider/mainimg.png")
const img3 = require("../images/slider/mainimg.png")

const heroSliderData = [
    {
        // 개행안됨 
        title: "\n코드 한 줄 없는\n  BIG DATA 분석 솔루션",
        description: "#복잡한 코드는 더이상 NO\n#강력한 AI 모델링 제공\n#크롤링부터 분석까지 원스탑으로 진행\n#타사대비 50% 저렴한 가격",
        img: img1,
        color: "blue",
        path: "/"
    },
    // {
    //     title: "코드 한 줄 없는 빅데이터 분석 솔루션",
    //     description: "코딩 없이 텍스트 데이터를 분석하여 인사이트 도출 시간을 70% 단축하세요. 크롤링부터 분석까지 쉽게 수행할 수 있는 강력한 AI 툴을 제공합니다.",
    //     img: img2,
    //     path: "/",
    //     color: "pink"
    // },
    // {
    //     title: "코드 한 줄 없는 빅데이터 분석 솔루션",
    //     description: "코드 없이 수백만 개의 텍스트 데이터를 분석할 수 있는 솔루션을 찾고 계신가요? AI 기반의 최신 툴들은 사용하기 쉬운 인터페이스와 강력한 분석 기능을 제공하여, 데이터 과학 지식 없이도 텍스트 데이터를 손쉽게 처리하고 인사이트를 도출할 수 있습니다. 텍스트 마이닝, 감정 분석, 주제 분류 등 다양한 기능을 통해 데이터에서 유의미한 정보를 빠르게 얻어보세요. (Written by ChatGPT)",
    //     img: img3,
    //     path: "/",
    //     color: "orange"
    // }
]

export default heroSliderData