import { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { rdswrite, mainColor } from '../config';
import Button from '../components/Button'
import imgSuccess from '../assets/images/warning.png';

const PayFail = () => {
    const history = useHistory();  // useHistory 훅 초기화
    const location = useLocation();
    const [isKeyLoaded, setIsKeyLoaded] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const productName = queryParams.get("productName");
    const productCode = queryParams.get("productCode");
    const serviceCode = queryParams.get("serviceCode");
    const paymentDate = queryParams.get("paymentDate");
    const profileID = queryParams.get("profileID");
    const transactionNo = queryParams.get("orderId");
    const estimatedCost = queryParams.get("amount");
    const requestCNT = queryParams.get("requestCNT")

    const formatDateString = (isoString) => {
        const date = new Date(isoString);

        const year = date.getFullYear(); // 연도
        const month = date.getMonth() + 1; // 월 (0부터 시작하므로 +1 필요)
        const day = date.getDate(); // 일

        const hours = date.getHours(); // 시
        const minutes = date.getMinutes(); // 분
        const seconds = date.getSeconds(); // 초

        return `${year}년 ${month}월 ${day}일 ${hours}시 ${minutes}분 ${seconds}초`;
    };

    useEffect(() => {


    }, []);



    return (

        <div className="result wrapper" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            fontSize: '1.5em',
        }}>
            <div className="box_section" ali>
                <br /><br /><br />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center'  // 텍스트 가운데 정렬
                    }}>
                        <h2>
                            <span style={{
                                color: mainColor,
                            }}>결제를 실패</span>
                            했어요.
                        </h2>
                    </div>
                <br />
                <div style={{
                    // display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'  // 텍스트 가운데 정렬
                }}>
                    <p>일시적인 오류로 결제가 완료되지 않았어요.</p>
                    <p>이전 페이지에서 결제를 다시 진행해 주세요.</p>
                </div>
                <br />
                <div>
                    <p style={{ fontSize: '16px', color: 'grey' }}>
                        {`📍주문내역: ${queryParams.get("service_code") === 'cr_0001' ? "유튜브 영상 댓글 " : "구글 플레이 스토어 앱 리뷰 "} ${Number(queryParams.get("requestCNT")).toLocaleString()}건 수집`}
                    </p>
                    <p style={{ fontSize: '16px', color: 'grey' }}>{`📍주문번호: ${queryParams.get("orderId")}`}</p>
                    <p style={{ fontSize: '16px', color: 'grey' }}>{`📍주문일시: ${formatDateString(queryParams.get("paymentDate"))}`}</p>
                    <p style={{ fontSize: '16px', color: 'grey' }}>{`📍결제 금액: ${Number(
                        queryParams.get("amount")
                    ).toLocaleString()}원`}</p>
                </div>
                <br />
                <div style={{
                    gap : '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'  // 텍스트 가운데 정렬

                }}>
                    <Button
                        size="sm"
                        backgroundColor={mainColor}
                        onClick={() => window.open("http://pf.kakao.com/_SxltHG/chat", '_blank')}
                    >
                        카톡 문의하기
                    </Button>
                    <Button
                        highlight="y"
                        size="sm"
                        backgroundColor={mainColor}
                        onClick={() => history.goback()}
                    >
                        이전 페이지
                    </Button>
                    
                </div>
            </div>
        </div>
    );
};

export default PayFail;
