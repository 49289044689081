const colors = [
    {
        display: "Trắng",
        color: "white"
    },
    {
        display: "Hồng",
        color: "pink"
    },
    {
        display: "Đen",
        color: "black"
    },
    {
        display: "Vàng",
        color: "yellow"
    },
    {
        display: "Cam",
        color: "orange"
    },
    {
        display: "Xanh dương",
        color: "blue"
    }
]

export default colors