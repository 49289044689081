const product_01_image_01 = require('../images/products/thumnails_unified/vis_1_wordcloud_unified.png')
const product_01_image_02 = require('../images/products/analysis_wordcloud_detail.png')
const product_01_image_detail = require('../images/products/analysis_tot_detail2.png')

const product_02_image_01 = require('../images/products/thumnails_unified/vis_2_keywordfreq_unified.png')
const product_02_image_02 = require('../images/products/analysis_keyword_detail.png')
const product_02_image_detail = require('../images/products/analysis_tot_detail2.png')

const product_03_image_01 = require('../images/products/thumnails_unified/vis_3_network_unified.png')
const product_03_image_02 = require('../images/products/analysis_network_detail.png')
const product_03_image_detail = require('../images/products/analysis_tot_detail2.png')

const product_04_image_01 = require('../images/products/ready.png')
const product_04_image_02 = require('../images/products/ready.png')
const product_04_image_detail = require('../images/products/ready.png')


const products = [
    {
        title: "⛅ 워드클라우드 시각화",
        urlInputGuideText : "* 수집할 앱 URL 입력 :",
        urlInputGuideTextSub : "※ 구글 플레이 스토어 App 상세 페이지 URL을 그대로 ‘복사+붙여넣기’ 해주세요.",
        dataCrawlingType : [
            { id: 1, text: '리뷰 내용' },
            { id: 2, text: '리뷰 평점' },
            { id: 3, text: '리뷰 등록일' },
            { id: 4, text: '작성자명' },
            { id: 5, text: '좋아요 개수' },
        ],
        price: '189000',
        image01: product_01_image_01,
        image02: product_01_image_02,
        imageDetail: product_01_image_detail,
        categorySlug: "visualize",
        colors: ["white", "red", "orange"],
        slug: "vis-wordcloud",
        size: ["s", "m", "l", "xl"],
        description: "단어빈도를 한눈에 확인하세요!",
        descriptionTag : "#트렌드 #데이터시각화"
    },
    {
        title: "📊 키워드 분석 차트",
        urlInputGuideText : "* 수집할 영상 URL 입력 :",
        urlInputGuideTextSub : "※ 수집할 영상 URL을 그대로 ‘복사+붙여넣기’ 해주세요.",
        dataCrawlingType : [
            { id: 1, text: '댓글 내용' },
            { id: 2, text: '댓글 등록일' },
            { id: 3, text: '댓글 작성자' },
            { id: 4, text: '좋아요 개수' },
            { id: 5, text: '대댓글' },
        ],
        price: '159000',
        image01: product_02_image_01,
        image02: product_02_image_02,
        imageDetail: product_02_image_detail,
        categorySlug: "visualize",
        colors: ["white", "red", "blue"],
        slug: "vis-word-occurrence-frequency",
        size: ["s", "m"],
        description: "키워드를 차트로 분석해보세요!",
        descriptionTag : "#키워드분석 #트렌드분석"
    },
    {
        title: "🕸️ 키워드 연결망 시각화",
        urlInputGuideText : "* 수집할 게시물 URL 입력 :",
        urlInputGuideTextSub : "※ 수집할 게시물 URL을 그대로 ‘복사+붙여넣기’ 해주세요.",
        dataCrawlingType : [
            { id: 1, text: '댓글 내용' },
            { id: 2, text: '댓글 등록일' },
            { id: 3, text: '댓글 작성자' },
            { id: 4, text: '좋아요 개수' },
            { id: 5, text: '대댓글' },
        ],
        price: '190000',
        image01: product_03_image_01,
        image02: product_03_image_02,
        imageDetail: product_03_image_detail,
        categorySlug: "visualize",
        colors: ["white", "red", "orange", "yellow"],
        slug: "vis-word-cooccurrence-network",
        size: ["m"],
        description: "키워드 간의 관계를 시각화!",
        descriptionTag : "#네트워크분석 #관계분석"
    },
    {
        title: "😀 다른 시각화 템플릿 요청",
        urlInputGuideText : "XXXXXX",
        urlInputGuideTextSub : "XXXXXX",
        dataCrawlingType : [
            { id: 1, text: '댓글 내용' },
            { id: 2, text: '댓글 등록일' },
            { id: 3, text: '댓글 작성자' },
            { id: 4, text: '좋아요 개수' },
            { id: 5, text: '대댓글' },
        ],
        price: '194000',
        image01: product_04_image_01,
        image02: product_04_image_02,
        imageDetail: product_04_image_detail,
        categorySlug: "visualize",
        colors: ["white", "orange", "blue"],
        slug: "vis-others",
        size: ["xl"],
        description: "",
        descriptionTag : ""
    },
]

const getAllProducts = () => products

const getProducts = (count) => {
    const max = products.length
    const min = 0
    // const start = Math.floor(Math.random() * (max - min) + min)
    return products.slice(min, min + max)
}

const getProductBySlug = (slug) => products.find(e => e.slug === slug)
const getProductByCategorySlug = (categorySlug) => products.find(e => e.categorySlug === categorySlug)

const getCartItemsInfo = (cartItems) => {
    let res = []
    if (cartItems.length > 0) {
        cartItems.forEach(e => {
            let product = getProductBySlug(e.slug)
            res.push({
                ...e,
                product: product
            })
        })
    }
    // console.log(res)
    // console.log('sorted')
    // console.log(res.sort((a, b) => a.slug > b.slug ? 1 : (a.slug < b.slug ? -1 : 0)))
    return res.sort((a, b) => a.id > b.id ? 1 : (a.id < b.id ? -1 : 0))
}

const productData = {
    getAllProducts,
    getProducts,
    getProductBySlug,
    getCartItemsInfo
}

export default productData