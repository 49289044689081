import React from 'react'

import Helmet from '../components/Helmet'
import Section, {SectionBody, SectionTitle} from '../components/Section'
import Grid from '../components/Grid'
import ProductCard from '../components/ProductCard'
import ProductView from '../components/ProductView'

import productData from '../assets/fake-data/products'
import productsScraperData from '../assets/fake-data/productsScraper'

import productDataAnalysis from '../assets/fake-data/productsAnalysis'
import productDataAI from '../assets/fake-data/productsAI'

import ProductViewVisualizer from '../components/ProductViewVisualizer'
import ProductViewAI from '../components/ProductViewAI'
import Preprocess from '../pages/Preprocess';
import DataScraper from '../pages/DataScraper'


const Product = props => {
    

    console.log(`props.match.params.slug: ${props.match.params.slug}`);
    //----------------------시각화 상품-크롤링 상품 구분하기----------------------
    const productType = props.match.params.slug;
    console.log(`productType: ${productType}`);
    let productRawData = [];

    // 시각화, 일반, AI 별로 다른상품 둘러보기의 상품이 다를 필요 없음
    // const productRawData = productType === "visualizer" ? productDataAnalysis : productData;
    if ( (productType == 'datascraper' ) || (productType == 'google-play-store-scraper') || (productType == 'youtube-comment-scraper')) {
        productRawData = productsScraperData;
    } else {
        productRawData = productData;
    }
    

    // 상품 타입에 맞는 데이터 원본 불러오기
    const product = productRawData.getProductBySlug(props.match.params.slug)
    const relatedProducts = productRawData.getProducts(8)
    //--------------------------------------------------------------------

    React.useEffect(() => {
        window.scrollTo(0,0)
    }, [product])


    // 상품 유형별 페이지 라우팅 | 24-09-15
    const productViewCategorize = () => {
        if (productType.includes("visualization")) return <ProductViewVisualizer product={product}/>
        else if (productType == ("datascraper")) return <DataScraper product={product}/>;
        else if ((productType.includes("google")) || (productType.includes("youtube")) ) return <ProductView product={product}/>;
        else if (productType.includes("ai-algorithm")) return <ProductViewAI product={product}/>;
        else if (productType.includes("preprocess")) return <Preprocess product={product}/>;
            
    };

    return (
        // <Helmet title={product.title}>
        <Helmet title={"test"}>
            <Section>
                <SectionBody>
                    {/* 크롤링 상품인 경우 */}
                    {productViewCategorize()}
                    {/* 시각화 상품인 경우 */}
                </SectionBody>
            </Section>
            {/* {
            ( ( (productType == 'datascraper' ) || (productType == 'google-play-store-scraper') || (productType == 'youtube-comment-scraper')) )
            ?
            ""
            :
            
            <Section>
                <SectionTitle>
                    다른 상품 둘러보기
                </SectionTitle>
                <SectionBody>
                    <Grid
                        col={4}
                        mdCol={2}
                        smCol={1}
                        gap={20}
                    >
                        { 
                        
                            relatedProducts.map((item, index) => (
                                <ProductCard
                                    key={index}
                                    img01={item.image01}
                                    img02={item.image02}
                                    name={item.title}
                                    price={Number(item.price)}
                                    slug={item.slug}
                                />   
                            ))
                        }
                    </Grid>
                </SectionBody>
            </Section>
            } */}
        </Helmet>
    )
}

export default Product
