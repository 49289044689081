import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom'
import ProductCard from '../components/ProductCard'

const product_01_image_01 = require('../assets/images/products/thumnails_unified/scraper_1_google-paly-store_unified.png')
const product_01_image_02 = require('../assets/images/products/scraper_google_app_detail.png')

const product_02_image_01 = require('../assets/images/products/thumnails_unified/scraper_2_yotube_unified.png')
const product_02_image_02 = require('../assets/images/products/scraper_youtube_detail.png')

const mainColor = 'rgb(70, 100, 166)';
const DataScraper = () => {

    const [profileID, setProfileID] = useState('');
    const [unixTime, setUnixTime] = useState(Math.floor(Date.now() / 1000));
    const [transactionNo, setTransactionNo] = useState("-");

    const history = useHistory();  // useHistory 훅 초기화

    useEffect(() => {
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');
    }, [profileID]);


    return (
        <div>
            <div style={{
                padding: '50px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start', // 위에서 시작하도록 설정
                textAlign: 'center',
                // height: '10vh', // 화면 전체 높이
                // paddingTop: '30vh', // 위에서 30% 높이에 배치
                margin: 0, // 여백 제거
            }}>
                <h1>
                    5분 안에 웹사이트의 Text 데이터를
                    <br />
                    자동으로 수집해 드립니다!
                </h1>
                <br />
                <h3 style={{ color: 'grey' }}>현재는 구글 플레이 스토어 앱 리뷰와 유튜브 영상 댓글 수집을 지원해 드려요🙏</h3>
                <br />
                <br />
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '100px' }}>
                    <ProductCard
                        key="google-play-store-scraper"
                        img01={product_01_image_01}
                        img02={product_01_image_02}
                        name="모바일 앱 리뷰 수집"
                        price={1234}
                        description="구글 플레이 스토어 리뷰 수집"
                        descriptionTag="#안드로이드앱 #리뷰크롤링"
                        slug="google-play-store-scraper"
                        categorySlug="scraper"
                    />
                    <ProductCard
                        key="youtube-comment-scraper"
                        img01={product_02_image_01}
                        img02={product_02_image_02}
                        name="유튜브 영상 댓글 수집"
                        price={1234}
                        description="영상 URL 입력 시 자동수집"
                        descriptionTag="#유튜브영상 #댓글수집"
                        slug="youtube-comment-scraper"
                        categorySlug="scraper"
                    />
                </div>
            </div>

            <div>

            </div>
        </div>

    );
};

export default DataScraper;