import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import cloud from "d3-cloud";

const WordCloud = ({ data }) => {
    const wordCloudRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 400, height: 300 });

    useEffect(() => {
        if (!data) return;

        // Resize listener for responsive behavior
        const handleResize = () => {
            if (wordCloudRef.current) {
                const width = wordCloudRef.current.offsetWidth || 400;
                const height = wordCloudRef.current.offsetHeight || 300;
                setDimensions({ width, height });
            }
        };

        handleResize(); // Initialize dimensions
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (!data || !dimensions.width || !dimensions.height) return;

        const { width, height } = dimensions;

        // Parse the input data
        const parsedData = data
            .trim()
            .split("\n")
            .slice(1) // Remove header
            .map((line) => {
                const [word, count] = line.split(",");
                return { text: word, count: +count, size: Math.sqrt(+count) * 10 };
            })
            .sort((a, b) => b.count - a.count) // Sort by count in descending order
            /********************상위 n개 단어********************* */
            .slice(0, 30); // Take top 20 items
            /********************상위 n개 단어********************* */


        // Clear previous SVG elements
        d3.select(wordCloudRef.current).selectAll("*").remove();

        // Initialize word cloud layout
        const layout = cloud()
            .size([width, height])
            .words(parsedData)
            .padding(3) // 글자 간 간격 줄이기
            .rotate(() => (Math.random() > 0.5 ? 90 : 0))
            .font("Impact")
            .fontSize((d) => d.size * 1.8) // 글자 크기 확대
            .on("end", draw);

        layout.start();

        function draw(words) {
            const svg = d3
                .select(wordCloudRef.current)
                .append("svg")
                .attr("width", width)
                .attr("height", height)
                .append("g")
                .attr("transform", `translate(${width / 2},${height / 2})`);

            svg.selectAll("text")
                .data(words)
                .enter()
                .append("text")
                .style("font-family", "SUIT-Regular")
                .style("fill", () => d3.schemeCategory10[Math.floor(Math.random() * 10)])
                .style("font-size", (d) => `${d.size}px`)
                .attr("text-anchor", "middle")
                .attr("transform", (d) => `translate(${d.x},${d.y}) rotate(${d.rotate})`)
                .text((d) => d.text)
                .on("mouseover", function (event, d) {
                    d3.select(this)
                        .transition()
                        .duration(200)
                        .style("font-size", `${d.size * 1.5}px`)
                        .style("fill", "orange");
                })
                .on("mouseout", function (event, d) {
                    d3.select(this)
                        .transition()
                        .duration(200)
                        .style("font-size", `${d.size}px`)
                        .style("fill", () =>
                            d3.schemeCategory10[Math.floor(Math.random() * 10)]
                        );
                });
        }

        // Cleanup previous SVG on component unmount
        return () => {
            d3.select(wordCloudRef.current).selectAll("*").remove();
        };
    }, [data, dimensions]);

    return (
        <div
            ref={wordCloudRef}
            style={{
                width: "100%",
                height: "300px",
                position: "relative",
                minHeight: "300px", // Default height
            }}
        />
    );
};

export default WordCloud;
