// const product_01_image_01 = require('../images/products/thumnails_unified/scraper_1_google-paly-store_unified.png')
const product_01_image_01 = require('../images/products/thumnails_unified/data-collect-img.png')
// const product_01_image_02 = require('../images/products/scraper_google_app_detail.png')
const product_01_image_02 = require('../images/products/data-collect-img-detail.png')
// const product_01_image_detail = require('../images/products/scraper_googleapp_detail1.png')
const product_01_image_detail = require('../images/products/scraper_google-play-store_241006_ff.png')

// const product_02_image_01 = require('../images/products/thumnails_unified/scraper_2_yotube_unified.png')
const product_02_image_01 = require('../images/products/thumnails_unified/data-preprocessing.png')
// const product_02_image_02 = require('../images/products/scraper_youtube_detail.png')
const product_02_image_02 = require('../images/products/data-preprocessing-detail.png')
// const product_02_image_detail = require('../images/products/scraper_youtube_detail1.png')
const product_02_image_detail = require('../images/products/scraper_youtube_comment_241006_ff.png')

const product_03_image_01 = require('../images/products/thumnails_unified/vis_3_network_unified.png')
const product_03_image_02 = require('../images/products/analysis_network_detail.png')
// const product_03_image_detail = require('../images/products/analysis_tot_detail2.png')
const product_03_image_detail = require('../images/products/analysis_tot_detail_f.png')

const product_04_image_01 = require('../images/products/thumnails_unified/vis_4_model.png')
const product_04_image_02 = require('../images/products/deeplearning.PNG')
// const product_04_image_detail = require('../images/products/scraper_ai_detail1.png')
const product_04_image_detail = require('../images/products/analysis_ai_detail1.png')

const productsScraper = [
    {
        title: "텍스트 데이터 수집",
        urlInputGuideText : "* 수집할 사이트 URL 입력 :",
        urlInputGuideTextSub : `수집하실 구글 플레이 스토어 앱의 URL을 입력해 주세요 :)`,
        dataCrawlingType : [
            { id: 1, text: '리뷰 작성일' },
            { id: 2, text: '작성자명' },
            { id: 3, text: '리뷰 평점' },
            { id: 4, text: '리뷰 내용' },
        ],
        price: '189000',
        image01: product_01_image_01,
        image02: product_01_image_02,
        imageDetail: product_01_image_detail,
        categorySlug: "scraper",
        colors: ["white", "red", "orange"],
        slug: "google-play-store-scraper",
        size: ["s", "m", "l", "xl"],
        description: "App 리뷰/Youtube 데이터 수집",
        descriptionTag : "#데이터크롤링   #자동수집"
    },
    {
        title: "하이라이트 AI",
        urlInputGuideText : "* 수집할 영상 URL 입력 :",
        urlInputGuideTextSub : "※ 수집할 영상 URL을 그대로 ‘복사+붙여넣기’ 해주세요.",
        dataCrawlingType : [
            { id: 1, text: '댓글 작성일' },
            { id: 2, text: '작성자명' },
            { id: 3, text: '댓글내용' },
            { id: 4, text: '좋아요 개수' },
            { id: 5, text: '대댓글 수집' },
        ],
        price: '159000',
        image01: product_02_image_01,
        image02: product_02_image_02,
        imageDetail: product_02_image_detail,
        categorySlug: "scraper",
        colors: ["white", "red", "blue"],
        slug: "youtube-comment-scraper",
        size: ["s", "m"],
        description: "AI 기반 주제별 핵심 문장 추출",
        descriptionTag : "#핵심문장추출   #문장토픽분류"
    },
    {
        title: "텍스트 데이터 시각화",
        urlInputGuideText : "* 수집할 게시물 URL 입력 :",
        urlInputGuideTextSub : "※ 수집할 게시물 URL을 그대로 ‘복사+붙여넣기’ 해주세요.",
        dataCrawlingType : [
            { id: 1, text: '댓글 내용' },
            { id: 2, text: '댓글 등록일' },
            { id: 3, text: '댓글 작성자' },
            { id: 4, text: '좋아요 개수' },
            { id: 5, text: '대댓글' },
        ],
        price: '190000',
        image01: product_03_image_01,
        image02: product_03_image_02,
        imageDetail: product_03_image_detail,
        categorySlug: "visualize",
        colors: ["white", "red", "orange", "yellow"],
        slug: "visualization",
        size: ["m"],
        description: "키워드를 한눈에 보이게 자동 시각화",
        descriptionTag : "#워드클라우드 #키워드연결망"
    },
    {
        title: "텍스트 감정 분류",
        urlInputGuideText : "XXXXXX",
        urlInputGuideTextSub : "XXXXXX",
        dataCrawlingType : [
            { id: 1, text: '댓글 내용' },
            { id: 2, text: '댓글 등록일' },
            { id: 3, text: '댓글 작성자' },
            { id: 4, text: '좋아요 개수' },
            { id: 5, text: '대댓글' },
        ],
        price: '194000',
        image01: product_04_image_01,
        image02: product_04_image_02,
        imageDetail: product_04_image_detail,
        categorySlug: "ai-algorithm",
        colors: ["white", "orange", "blue"],
        slug: "ai-algorithm",
        size: ["xl"],
        description: "긍부정/주제별 문장 자동 분류",
        descriptionTag : "#AI #감정분류 #의도분류"
    },
    
]

const getAllProducts = () => productsScraper

const getProducts = (count) => {
    const max = productsScraper.length
    const min = 0
    // const start = Math.floor(Math.random() * (max - min) + min)
    return productsScraper.slice(min, min + max)
}

const getProductBySlug = (slug) => productsScraper.find(e => e.slug === slug)

const getCartItemsInfo = (cartItems) => {
    let res = []
    if (cartItems.length > 0) {
        cartItems.forEach(e => {
            let product = getProductBySlug(e.slug)
            res.push({
                ...e,
                product: product
            })
        })
    }
    // console.log(res)
    // console.log('sorted')
    // console.log(res.sort((a, b) => a.slug > b.slug ? 1 : (a.slug < b.slug ? -1 : 0)))
    return res.sort((a, b) => a.id > b.id ? 1 : (a.id < b.id ? -1 : 0))
}

const productsScraperData = {
    getAllProducts,
    getProducts,
    getProductBySlug,
    getCartItemsInfo
}

export default productsScraperData