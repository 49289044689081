import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';

import { addItem } from '../redux/shopping-cart/cartItemsSlide';
import { remove } from '../redux/product-modal/productModalSlice';

import Button from './Button';
import numberWithCommas from '../utils/numberWithCommas';
import Swal from 'sweetalert2';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';

import loadingDefault from '../assets/images/loading_default.gif';
import '../sass/FileUpload.css'
import visImgSample from '../assets/images/vis_example_240707.png';
import errorMsgImg from '../assets/images/error_msg_img_resize.png'

import FileSelectBtn from './FileUpload';
import { API_S3_GETOBJ1, mainColor, UploadFileAPI, visualizeKeywordFreqAPI, visualizeNetworkAPI } from '../config';
import InfoTooltip from './InfoTooltip';

import WriteRDS from './WriteRDS';
import EventTracker from './EventTracker';

import cloud from "d3-cloud";
import { Bar } from "react-chartjs-2";
import { toPng } from "html-to-image";
import { useRef } from 'react';

import WordCloud from "./WordCloud";
import KeywordFrequencyChart from "./KeywordFrequencyChart";
import KeywordNetwork from "./KeywordNetwork";

// 차트 위함
import { CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Chart as ChartJS } from 'chart.js';
// Chart.js에서 필요한 요소 등록
ChartJS.register(
    CategoryScale,  // CategoryScale 등록
    LinearScale,    // LinearScale 등록
    BarElement,     // Bar chart 요소
    Title,          // 제목 요소
    Tooltip,        // 툴팁 요소
    Legend          // 범례 요소
);

const ProductViewVisualizer = (props) => {
    const disabledBtnColor = 'grey'
    const dispatch = useDispatch();

    let pageName="visualization";
    let pageCode="p0006";
    let eventCode="";
    let eventName="";
    let values = [];
    const tableNameEventInfo = 'tb_event_info';

    let product = props.product || {
        title: "",
        price: '',
        image01: null,
        image02: null,
        categorySlug: "",
        colors: [],
        slug: "",
        size: [],
        description: "",
        descriptionTag: "",
        dataCrawlingType: [],
        urlInputGuideText: "",
        urlInputGuideTextSub: "",
    };

    const [previewImg, setPreviewImg] = useState(product.imageDetail);
    const [descriptionExpand, setDescriptionExpand] = useState(false);
    const [color, setColor] = useState(undefined);
    const [size, setSize] = useState(undefined);
    const [quantity, setQuantity] = useState(1);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUploadBtnDisabled, setFileUploadBtnDisabled] = useState(false);
    const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState(false);
    const [data, setData] = useState([]);
    const [dataUploadFlag, setDataUploadFlag] = useState(false);
    const [errorFlag, setErrorFlag] = useState(false);
    const [uploadFileHeaders, setUploadFileHeaders] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState('');
    const [selectedDateColumn, setSelectedDateColumn] = useState('');
    const [fileValidation, setFileValidation] = useState(false);

    //----------------------파일/시각화 버튼 컬러----------------------//
    const [fileUploadBtnColor, setFileUploadBtnColor] = useState(disabledBtnColor);
    //------------------------------------------------------------//

    //-------------------output img 할당변수-------------------//
    const [imgWordCloud, setImgWordCloud] = useState(null);
    const [imgKeywordFreq, setImgKeywordFreq] = useState(null);
    const [imgWordNetwork, setImgWordNetwork] = useState(null);
    //-------------------------------------------------------//

    const [visualizeKeywordFreqIsClicked, setVisualizeKeywordFreqIsClicked] = useState(false);
    const [visualizeNetworkIsClicked, setVisualizeNetworkIsClicked] = useState(false);

    const [loadingFileUpload, setLoadingFileUpload] = useState(false);
    const [loadingVisKeywordFreq, setLoadingVisKeywordFreq] = useState(false);
    const [loadingVisNetwork, setLoadingVisNetwork] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [profileID, setProfileID] = useState('');
    const history = useHistory();  // useHistory 훅 초기화

    const [transactionNo, setTransactionNo] = useState("-");
    const [productCode, setProductCode] = useState('-');
    const [serviceCode, setServiceCode] = useState('-');
    const [unixTime, setUnixTime] = useState(Math.floor(Date.now() / 1000));

    const [keywordFreqData, setKeywordFreqData] = useState(null);
    const [keywordNetworkData, setKeywordNetworkData] = useState(null);
    const [loading, setLoading] = useState(false);

    const [wordclouddata, setWordCloudData] = useState(null);
    const [keywordfreqchartdata, setKeywordFreqChartData] = useState(null);
    const [networkdata, setNetworkData] = useState(null);

    const keywordFreqRef = useRef(null); // 키워드 빈도 차트
    const wordCloudRef = useRef(null); // 워드클라우드 시각화
    const networkRef = useRef(null); // 키워드 연결망 시각화

    const [chartData, setChartData] = useState({
        labels: [], // 빈 배열로 초기화
        datasets: [
            {
                label: "Word Frequency",
                data: [],
                backgroundColor: "rgba(75,192,192,0.6)",
                borderColor: "rgba(75,192,192,1)",
                borderWidth: 1,
            },
        ],
    });
    //====================준비 중인 서비스 클릭 시 동작====================
    // const currentUrl = window.location.href;
    // if (currentUrl.includes('vis-others')) {
    //     Swal.fire({
    //         icon: 'error',
    //         title: '🛠️서비스 준비 중',
    //         html: "8월 중 오픈 예정입니다 :)",
    //         confirmButtonText: '확인',
    //     });
    //     history.push('/');
    // }
    //==============================================================

    useEffect(() => {
        setPreviewImg(product.imageDetail);
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');

        if (loadingFileUpload) {
            const maxWaitTime = 30000; // 최대 대기 시간
            const minWaitTime = 5000;  // 최소 대기 시간
            const start = Date.now();

            const checkLoadingStatus = () => {
                const elapsed = Date.now() - start;

                if (elapsed >= maxWaitTime && (!imgKeywordFreq || !imgWordCloud | !imgWordNetwork)) {
                    setLoadingFileUpload(false);
                    setLoadingComplete(true);
                } else if (elapsed >= minWaitTime && !loadingComplete) {
                    // False로 변경되면 타이머 즉시 종료
                    setLoadingComplete(true);
                } else {
                    setTimeout(checkLoadingStatus, 100); // 0.1초마다 상태 확인
                }
            };

            checkLoadingStatus();

            return () => clearTimeout(checkLoadingStatus); // 컴포넌트 unmount 시 타이머 정리
        }

    }, [product, loadingFileUpload, loadingVisNetwork]);
    console.log(`profileID: ${profileID}`);

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([1, 2, 3, 4, 5]);

    const checkboxTextData = [
        { id: 1, text: '리뷰 내용' },
        { id: 2, text: '리뷰 평점' },
        { id: 3, text: '리뷰 등록일' },
        { id: 4, text: '작성자명' },
        { id: 5, text: '좋아요 개수' },
    ];

    const optionPrices = {
        option1: 10000,
        option2: 15000,
        option3: 20000,
        option4: 25000,
        option5: 30000,
    };

    const [selectedOption, setSelectedOption] = useState('option1');
    const [estimatedCost, setEstimatedCost] = useState(optionPrices.option1);

    const showWaitPopup = () => {
        Swal.fire({
            icon: 'info',
            title: '📊시각화 작업 중이예요!',
            html: '최대 30초까지 소요돼요. 잠시만 기다려주세요.<br/>새로고침하시면 파일 업로드부터 다시 하셔야 돼요.',
            timer: 30000,
            allowOutsideClick: false, // 팝업 밖을 클릭해도 닫히지 않음
            allowEscapeKey: false,    // ESC 키로 닫을 수 없게 설정
            allowEnterKey: false,     // 엔터 키로 닫을 수 없게 설정
            timerProgressBar: true,   // 타이머 진행 상황 표시
            didOpen: () => {
                Swal.showLoading();     // 팝업에 로딩 애니메이션 표시
            },
        });

        if (!loadingVisKeywordFreq && !loadingVisNetwork){ //둘다 로딩 완료됐을때
        // if (wordclouddata && keywordfreqchartdata && networkdata) {
            Swal.close();  // 조건이 만족되면 팝업 닫기
        }
    };

    const trackEvent = async () => {
        let eventParams = {
            pageName: pageName,
            pageCode: pageCode,
            eventName: eventName,
            eventCode: eventCode,
            transactionNo: transactionNo,
        };

        values  = EventTracker(eventParams);

        // WriteRDS 호출로 데이터베이스에 적재
        await WriteRDS(tableNameEventInfo, values);
    };

    const handleOptionChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedOption(selectedValue);
        if (selectedValue && optionPrices[selectedValue] !== undefined) {
            const cost = optionPrices[selectedValue];
            setEstimatedCost(cost);
        } else {
            setEstimatedCost(0);
        }
    };

    ///////////////// 불용어 등록 ///////////////////
    const [queue1, setQueue1] = useState([]);
    const [queue2, setQueue2] = useState([]);
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');

    const handleChange1 = (e) => {
        setInput1(e.target.value);
    };
    const handleKeyPress1 = (e) => {
        eventName = "stopword_add";
        eventCode = "e0020";
        trackEvent();

        if (!selectedFile) {
            Swal.fire({
                icon: 'error',
                title: 'csv 파일을 먼저 업로드해 주세요',
                html: '현재 2.5MB 이하의 파일만 분석할 수 있어요',
                confirmButtonText: '확인',
            });
            return;
        }

        if (e.key === 'Enter' && input1.trim() !== '') {
            setQueue1([input1, ...queue1]);
            setInput1('');
        }
    };
    const handleRemove1 = (index) => {
        eventName = "stopword_remove";
        eventCode = "e0021";
        trackEvent();
        setQueue1(queue1.filter((_, i) => i !== index));
    };
    const handleChange2 = (e) => {
        setInput2(e.target.value);
    };
    const handleKeyPress2 = (e) => {
        eventName = "propernoun_add";
        eventCode = "e0022";
        trackEvent();
        if (!selectedFile) {
            Swal.fire({
                icon: 'error',
                title: 'csv 파일을 먼저 업로드해 주세요',
                html: '현재 2.5MB 이하의 파일만 분석할 수 있어요',
                confirmButtonText: '확인',
            });
            return;
        }

        if (e.key === 'Enter' && input2.trim() !== '') {
            setQueue2([input2, ...queue2]);
            setInput2('');
        }
    };
    const handleRemove2 = (index) => {
        eventName = "propernoun_remove";
        eventCode = "e0023";
        trackEvent();
        setQueue2(queue2.filter((_, i) => i !== index));
    };
    ////////////////////////////////////////////////////


    const uploadFileS3 = async () => {

        try {
            setLoadingFileUpload(true);
            setImgKeywordFreq(null);
            setImgWordCloud(null);
            setImgWordNetwork(null);

            Swal.fire({
                title: '💨파일 업로드 중',
                html: '최대 10초까지 소요되니 잠시만 기다려주세요.',
                icon: 'info',
                allowOutsideClick: false,
                timer: 10000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading(); // 로딩 애니메이션 시작
                },
            });

            eventName = "upload_file";
            eventCode = "e0014";
            trackEvent();

            if (!profileID) {
                Swal.fire({
                    icon: 'error',
                    title: '🎈로그인 필요!',
                    html: "데이터 시각화는 무료이나 로그인이 필요해요 :)",
                    confirmButtonText: '로그인하러 가기',
                });
                history.push('/login');
            }

            // 파일 미선택 시 경고 팝업
            if (!fileUploadedSuccessfully) {
                Swal.fire({
                    icon: 'warning',
                    title: 'csv 파일을 업로드해 주세요',
                    html: '현재 2.5MB 이하의 파일만 분석할 수 있어요',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;
            }
            // 파일 용량 제한 초과 시
            if (selectedFile.size > 2.5e+6) {
                console.log(`업로드 파일 업로드 용량 초과 : ${selectedFile.size}`);
                Swal.fire({
                    icon: 'error',
                    title: '최대 용량 초과',
                    html: '2.5MB 이하의 csv 파일만 업로드해 주세요.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;

            }

            if (selectedFile.size <= 2.5e+6) { }
            else {
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    html: 'csv 파일만 업로드해 주세요.<br/>문제 해결이 안 되면 카톡플러스친구로 문의바랍니다.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;
            }
            const fileType = selectedFile.name.split('.').pop();
            // dataPreviewSetup(fileType);
            const reader = new FileReader();
            const fileExtension = selectedFile.name.split('.').pop();
            console.log(`fileExtension: ${fileExtension}`);
            if (fileExtension != 'csv' && fileExtension != 'xlsx' && fileExtension != 'xls') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: '현재 csv 파일만 업로드 가능합니다.',
                    confirmButtonText: '확인',
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;
            } else {
                console.log('유효한 파일 형식');

                setFileValidation(true);
                setFileUploadedSuccessfully(true);
                setFileUploadBtnColor(disabledBtnColor);

                // 파일을 읽는 방식을 정의 241006
                if (fileExtension === 'csv') {
                    reader.readAsText(selectedFile, 'UTF-8');  // UTF-8 인코딩으로 읽기
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    reader.readAsArrayBuffer(selectedFile);  // XLSX 파일은 ArrayBuffer로 읽음
                }

                reader.onload = (e) => {
                    const fileData = e.target.result;
                    const generateUniqueHeader = (headers) => {

                        console.log(`-----generateUniqueHeader 함수 실행-----`);
                        console.log(`headers.length : ${headers.length}`);
                        console.log(`headers[0] : ${headers[0]}`);
                        console.log(`type(headers[0]) : ${typeof (headers[0])}`);
                        console.log(`headers[1] : ${headers[1]}`);
                        console.log(`headers 자체 : ${headers}`);
                        // const newHeaders = headers.map(item => typeof(item) === undefined ? "undefinedColumn" : item);
                        const newHeaders = Array.from(headers, item => {
                            if (typeof item === 'undefined') {
                                return "undefinedColumn";
                            } else {
                                return item;
                            }
                        })

                        const uniqueHeaders = [];
                        const headerCount = {};
                        console.log(`newHeaders[0] : ${newHeaders[0]}`);
                        console.log(`type(newHeaders[0]) : ${typeof (newHeaders[0])}`);
                        console.log(`newHeaders 자체 : ${newHeaders}`);

                        newHeaders.forEach((header, index) => {
                            console.log(`현재 header : ${header}`);

                            if (header === "" || header === "undefined") {
                                console.log(`현재 header 비어있거나 undefinde : ${header}`);
                                header = "undefinedColumn";
                            }

                            if (uniqueHeaders.includes(header)) {
                                let count = headerCount[header] || 1;
                                let newHeader = `${header}${count}`;

                                while (uniqueHeaders.includes(newHeader)) {
                                    count += 1;
                                    newHeader = `${header}${count}`;
                                }

                                uniqueHeaders.push(newHeader);
                                headerCount[header] = count + 1;
                            } else {
                                uniqueHeaders.push(header);
                                headerCount[header] = 1;
                            }
                        });

                        return uniqueHeaders;
                    };

                    if (fileExtension === 'csv') {
                        Papa.parse(fileData, {
                            header: true,
                            complete: (results) => {
                                const csvData = results.data;
                                console.log(`csvData: ${csvData}`);
                                let headers = Object.keys(csvData[0]);
                                console.log(`csvData[1]: ${Object.keys(csvData[1])}`);
                                headers = generateUniqueHeader(headers);
                                console.log(`headers: ${headers}`);
                                console.log(`headers type: ${typeof (headers)}`);
                                // 처음 5개의 행만 선택
                                const rows = csvData.slice(0, 5).map(Object.values);
                                console.log(`rows: ${rows}`);
                                setData([headers, ...rows]);
                            },
                        });
                    }
                    else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                        const workbook = XLSX.read(fileData, { type: 'binary' });
                        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                        let headers = jsonData[0];
                        console.log(`jsonData[0]: ${jsonData[0]}`);

                        headers = generateUniqueHeader(headers);
                        jsonData[0] = headers;
                        // 헤더의 일부 셀이 비어 있거나 "undefined"면 unique header 생성
                        if (jsonData.length > 0) {

                        }
                        setData(jsonData.slice(0, 5));
                    }
                };

                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('profileID', profileID);

                try {
                    const response = await axios.post(UploadFileAPI, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((res) => {
                        console.log('File uploaded successfully:', res.data);
                        setFileUploadedSuccessfully(true);
                        Swal.fire({
                            icon: 'success',
                            title: '파일 업로드 성공',
                            text: `이제 분석할 컬럼을 선택해 주세요`,
                            confirmButtonText: '확인',
                            didOpen: () => {
                                Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                            }
                        });
                        setDataUploadFlag(true);
                        setLoadingFileUpload(false);
                    });

                } catch (error) {
                    console.error('Error uploading file:', error);
                    Swal.fire({
                        icon: 'error',
                        title: '👀파일 상태를 확인해 주세요',
                        html: '파일에 빈 행이 있거나 컬럼명이 없지 않은지 확인해 주세요 :)<br/>계속 문제 해결이 안 되면 카톡문의 부탁드려요',
                        confirmButtonText: '확인',
                        didOpen: () => {
                            Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                        }
                    });
                    setDataUploadFlag(false);
                    setLoadingFileUpload(false);
                    setData([]);
                    setSelectedFile("");
                    setFileValidation(false);
                    setFileUploadedSuccessfully(false);
                }

            }
        } catch (error) {
            console.log(`파일 업로드 중 에러 발생 : ${error}`);
            Swal.fire({
                icon: 'error',
                title: '👀파일 상태를 확인해 주세요',
                html: '파일에 빈 행이 있거나 컬럼명이 없지 않은지 확인해 주세요 :)<br/>계속 문제 해결이 안 되면 카톡문의 부탁드려요',
                confirmButtonText: '확인',
                didOpen: () => {
                    Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                }
            });
            setDataUploadFlag(false)
            setLoadingFileUpload(false);
            setFileValidation(false);
            setFileUploadedSuccessfully(false);
            setData([]);
            setSelectedFile("");
        }
    }


    const onFileChange = (event) => {
        try {
            console.log(`event.target.files[0]: ${event.target.files[0]}`);
            setSelectedFile(event.target.files[0]);
            const reader = new FileReader();
            const fileExtension = event.target.files[0].name.split('.').pop();
            console.log(`fileExtension: ${fileExtension}`);
            // if (fileExtension != 'csv' && fileExtension != 'xlsx' && fileExtension != 'xls') {
            if (fileExtension != 'csv') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: 'csv 파일만 업로드 가능합니다. 변환후 업로드 해 주세요.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setSelectedFile(null);
                return;
            } else {
                // 유효한 파일 확인 case
                setFileUploadBtnColor(mainColor);
                setFileUploadedSuccessfully(true);
                // dataPreviewSetup(fileExtension);
            };

        } catch (err) {
            console.log(`error 발생: ${err}`);
        }
    };

    const calculateColumnWidths = () => {
        if (data.length === 0) return [];
        const columnWidths = data[0].map((_, colIndex) => {
            const maxLength = Math.max(
                ...data.map((row) => (row[colIndex] ? row[colIndex].toString().length : 0))
            );
            return maxLength * 9; // Adjust the multiplier as needed
        });
        return columnWidths;
    };

    const dataPreviewSetup = (fileExtension) => {
        try{
            const reader = new FileReader();
            console.log(`fileExtension: ${fileExtension}`);
            if (fileExtension != 'csv') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: 'csv 파일만 업로드 가능합니다. 변환후 업로드 해 주세요.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setSelectedFile(null);
                return;
            } else {
                console.log('유효한 파일 형식');
                setFileValidation(true);
                setFileUploadedSuccessfully(true);
                setFileUploadBtnColor(disabledBtnColor);

                // 파일을 읽는 방식을 정의 위치 수정 241006
                if (fileExtension === 'csv') {
                    reader.readAsText(selectedFile, 'UTF-8');  // UTF-8 인코딩으로 읽기
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    reader.readAsArrayBuffer(selectedFile);  // XLSX 파일은 ArrayBuffer로 읽음
                }

                reader.onload = (e) => {
                    const fileData = e.target.result;
                    if (fileExtension === 'csv') {
                        Papa.parse(fileData, {
                            header: true,
                            complete: (results) => {
                                const csvData = results.data;
                                console.log(`csvData: ${csvData}`);
                                const headers = Object.keys(csvData[0]);
                                console.log(`headers: ${headers}`);
                                console.log(`headers type: ${typeof (headers)}`);
                                const rows = csvData.map(Object.values);
                                console.log(`rows: ${rows}`);
                                setData([headers, ...rows]);
                            },
                        });
                    } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                        const workbook = XLSX.read(fileData, { type: 'binary' });
                        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                            header: 1

                        });
                        setData(jsonData.slice(0, 10));

                    }
                };
                if (fileExtension === 'csv') {
                    console.log("fileExtension === 'csv'");
                    reader.readAsText(selectedFile);
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    console.log("fileExtension === 'xlsx' || fileExtension === 'xls'");
                    reader.readAsBinaryString(selectedFile);
                }

            }}
        catch (error){
            setDataUploadFlag(false);
            setLoadingFileUpload(false);
            setData([]);
            setSelectedFile("");
            setFileValidation(false);
            setFileUploadedSuccessfully(false);
            Swal.fire({
                icon: 'error',
                title: '👀파일 상태를 확인해 주세요',
                html: '파일에 빈 행이 있거나 컬럼명이 없지 않은지 확인해 주세요 :)<br/>계속 문제 해결이 안 되면 카톡문의 부탁드려요',
                confirmButtonText: '확인',
                didOpen: () => {
                    Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                }
            });

        }

    }

    const dataPreviewShow = () => {
        const columnWidths = calculateColumnWidths();
        if (loadingFileUpload) {
            return (
                <div
                    style={{
                        width: '100%',
                        height: '400px',
                        border: '0.5px solid grey',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0 auto',
                        fontSize: '1.5em',
                        borderRadius: '8px', // 둥근 모서리 추가
                        padding: '3px', // 내부 여백 추가
                        color: 'grey',
                    }}
                >

                    <img style={{ height: '150px' }} src={loadingDefault} alt="로딩 중..." />
                    <p>파일 업로드는 최대 30초까지 걸려요 :)</p>
                </div>
            )
        }

        else if (!loadingFileUpload && dataUploadFlag) {
            return (
                <div>
                    <br />
                    <table style={{ borderCollapse: 'collapse', border: '1px solid black' }}>
                        <thead>
                            <tr>
                                {data[0] &&
                                    data[0].map((header, index) => (
                                        <th key={index} style={{ width: columnWidths[index] + 20, border: '1px solid black', padding: '8px' }}>
                                            {header}
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.slice(1).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex} style={{ width: columnWidths[cellIndex] + 20, border: '1px solid black', padding: '8px' }}>
                                            {cell}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )
        }

        else if (!loadingFileUpload) {
            return (<div
                style={{
                    width: '100%',
                    height: '200px',
                    border: '0.5px solid grey',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto',
                    fontSize: '1.5em',
                    borderRadius: '10px', // 둥근 모서리 추가
                    padding: '3px', // 내부 여백 추가
                    color: 'grey',
                }}
            >
                파일 업로드 시 일부 Data가 미리 보여집니다.
            </div>)
        }
    };

    const showErrorImg = () => {
        if (errorFlag && !imgKeywordFreq && !imgWordCloud && !imgWordNetwork) {
            return (
                <img src={errorMsgImg} style={{ height: '50%', border: '0.5px solid grey' }}>
                </img>
            )
        }
    }

    const handleColumnChange = (event) => {
        setSelectedColumn(event.target.value);
    };

    // // 시계열 데이터
    // const handleDateColumnChange = (event) => {
    //     setSelectedDateColumn(event.target.value);
    // };

    // 분석할 컬럼 선택 통합
    const selectColumnTotal = () => {
        return dataUploadFlag ? (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '7px', }}>
                    <select value={selectedColumn} onChange={handleColumnChange}
                        style={{
                            padding: '10px', fontSize: '14px', borderRadius: '12px',
                            color: mainColor, cursor: 'pointer', border: `0.5px solid grey`,
                            transition: 'background-color 0.3s ease, color 0.3s ease'
                        }}>
                        <option value="" disabled>❗️[필수] 텍스트 분석할 열 선택 </option>
                        {data[0].map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <InfoTooltip text="❗️[필수] 텍스트가 포함된 열을 선택해 주세요" />
                </div>
            </div>
        )
            :
            (
                null
            )
    }

    
    const fetchS3Data = async () => {
        console.log('fetchS3Data 호출됨.');
        console.log(`profileID: ${profileID}, unixTime: ${unixTime}`);

        const fetchFile = async (fileName, productCode) => {
            if (!fileName) return null;
    
            const req = {
                "profileid": profileID,
                "serviceCode": 'an_0001',
                "productCode": productCode,
                "request_cnt": '1000',
                "fileName": fileName,
                // "unix_time": unixTime,
                "unix_time": '',
            };
    
            try {
                const response = await axios.post(API_S3_GETOBJ1, req, {
                    headers: { 'Content-Type': 'application/json' }, // 헤더 수정
                });
    
                const fileData = response.data.body; // CSV 데이터 반환
                return fileData;
            } catch (error) {
                console.error(`Error fetching file ${fileName}:`, error);
                return null;
            }
        };
    
        try {
            const [freqData, networkData] = await Promise.all([
                fetchFile(keywordFreqData, ''), //!!!!!바뀌면 downloader lambda 도 신경써야됨!!!!!
                fetchFile(keywordNetworkData, ''), //!!!!!바뀌면 downloader lambda 도 신경써야됨!!!!!
            ]);
    
            if (freqData) {
                setWordCloudData(freqData); // 워드클라우드 데이터 설정
                setKeywordFreqChartData(freqData); // 키워드 빈도 차트 데이터 설정
            } else {
                console.warn('Frequency Data is null or empty.');
            }
    
            if (networkData) {
                setNetworkData(networkData); // 키워드 연결망 데이터 설정
            } else {
                console.warn('Network Data is null or empty.');
            }
        } catch (error) {
            console.error('Error during data fetching:', error);
        }
    };
    
    const onVisualizeKeywordFreq = async () => {
        setLoadingVisKeywordFreq(true);
        console.log(`키워드 빈도 차트 시각화 버튼 클릭!`);
        setVisualizeKeywordFreqIsClicked(true);
        try {
            const req = {
                "profileID": profileID,
                "filename": selectedFile.name,
                "selectedColumn": selectedColumn,
                "transactionNo": transactionNo,
                "service_code": serviceCode,
                "product_code": productCode,
                "stopword": queue1,
                "proper_noun": queue2,
                "unix_time": unixTime,
            };
            console.log('Request Data:', req); // 요청 데이터 로그 출력

            const response = await axios.post(visualizeKeywordFreqAPI, req, {
                headers: {'Content-Type': 'application/json'},
            });
            setKeywordFreqData(response.data.s3FileWordfrequency); //워드클라우드, 키워드빈도 둘다 그려야됨.
            setLoadingVisKeywordFreq(false);
            // setKeywordFreqData(response.data.s3FileWordfrequency); //S3에 저장된 파일명이 반환됨.
        } catch (error) {
            console.error("Error fetching keyword frequency data:", error);
            setErrorFlag(true);
        } finally {
            setLoading(false);
        }
    };

    const onVisualizeNetwork = async () => {
        setLoadingVisNetwork(true);
        setVisualizeNetworkIsClicked(true);
        console.log(`네트워크 시각화 버튼 클릭!`);
        try {
            const req = {
                "profileID": profileID,
                "filename": selectedFile.name,
                "selectedColumn": selectedColumn,
                "transactionNo": transactionNo,
                "service_code": serviceCode,
                "product_code": productCode,
                "stopword": queue1,
                "proper_noun": queue2,
                "unix_time": unixTime,
            };
    
            console.log(`unix_time : ${unixTime}`);
    
            const response = await axios.post(visualizeNetworkAPI, req, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setKeywordNetworkData(response.data.s3FileNetwork);
            setLoadingVisNetwork(false);
        } catch (error) {
            console.error("Error fetching keyword network data:", error);
        }
    };

    const visualizeTotal = () => {
        eventName = "visualize";
        eventCode = "e0015";
        trackEvent();

        if (!selectedFile) {
            Swal.fire({
                icon: 'error',
                title: 'csv 파일을 먼저 업로드해 주세요',
                html: '현재 2.5MB 이하의 파일만 분석할 수 있어요',
                confirmButtonText: '확인',
            });
            return;
        }

        const reader = new FileReader();
        const fileExtension = selectedFile.name.split('.').pop();

        if (fileExtension === 'csv') {
            reader.readAsText(selectedFile, 'UTF-8');  // UTF-8 인코딩으로 읽기
        } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
            reader.readAsArrayBuffer(selectedFile);  // XLSX 파일은 ArrayBuffer로 읽음
        }

        reader.onload = (e) => {
            const fileData = e.target.result;

            Papa.parse(fileData, {
                header: true,
                complete: (results) => {
                    const csvData = results.data; // CSV 파일의 데이터
                    // 첫 번째 행을 헤더로 사용한 후 두 번째 행 탐색
                    if (csvData.length > 1) {
                        if (selectedColumn) {
                            setUnixTime(Math.floor(Date.now() / 1000));
                            onVisualizeKeywordFreq();
                            onVisualizeNetwork();
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: '❗️분석할 컬럼 선택 필요',
                                html: "파일 업로드와 분석하실 컬럼을 먼저 선택해 주세요",
                                confirmButtonText: '확인',
                            });
                            return
                        }
                    }
                }
            });
        };
    }
    
    const saveVisImg = async (elementId, filename = "visualization.png") => {
        const chartElement = document.getElementById(elementId);
    
        if (!chartElement) {
            console.error(`ID가 ${elementId}인 요소를 찾을 수 없습니다.`);
            return;
        }
    
        try {
            const dataUrl = await toPng(chartElement, {
                skipFonts: true, // 폰트 임베딩 비활성화
            });
            const link = document.createElement('a');
            link.download = filename;
            link.href = dataUrl;
            link.click();
        } catch (error) {
            console.error('이미지를 저장하는 중 오류가 발생했습니다:', error);
        }
    };


    const ShowVisualize = () => { 
        return (
            <div>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <div style={{ width: '100%', height: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h2>☁️ 워드클라우드</h2>
                        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <Button
                                size="sm"
                                backgroundColor={mainColor}
                                onClick={() => saveVisImg("wordcloud", "wordcloud.png")}
                            >
                                다운로드
                            </Button>
                        </div>        
                        {/* <div id="wordcloud" style={{ flexBasis: '30%', boxSizing: 'border-box' }}>
                            <WordCloud data={wordclouddata} />
                        </div> */}
                    </div>                                      
                    <br />
                    <div style={{ width: '100%', height: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h2>📊 키워드 빈도차트</h2>
                        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <Button
                                size="sm"
                                backgroundColor={mainColor}
                                onClick={() => saveVisImg("keywordfrequencychart", "keyword-frequency-chart.png")}
                            >
                                다운로드
                            </Button>
                        </div>
                        {/* <div id="keywordfrequencychart" style={{ flexBasis: '30%', boxSizing: 'border-box' }}>
                            <KeywordFrequencyChart data={keywordfreqchartdata} />
                        </div> */}
                    </div>
                    <br />
                    <div style={{ width: '100%', height: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h2>🕸️ 키워드 연결망</h2>
                        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <Button
                                size="sm"
                                backgroundColor={mainColor}
                                onClick={() => saveVisImg("keywordnetwork", "keyword-network.png")}
                            >
                                다운로드
                            </Button>
                        </div>
                        {/* <div id="keywordnetwork" style={{ flexBasis: '30%', boxSizing: 'border-box' }}>
                            <KeywordNetwork data={networkdata} />
                        </div> */}
                    </div>
                </div>
                <br/>
                <div style={{ display: 'flex', gap: '10px' }}>
                    {handleVisualizeKeywordFreq()}
                </div>
            </div>
        );
    };
    
    const handleVisualizeKeywordFreq = () => {
        console.log("handleVisualizeKeywordFreq called"); // 함수 호출 확인
    
        // 데이터가 아직 로드되지 않은 경우 로딩 상태를 표시
        // 둘다 로딩중일때
        if (loadingVisKeywordFreq && loadingVisNetwork) {
            console.log("Loading state detected: loadingVisKeywordFreq =", loadingVisKeywordFreq, "loadingVisNetwork =", loadingVisNetwork);
            return showWaitPopup();
        }
    
        // 데이터가 없는 경우 기본 이미지를 표시
        if (!visualizeKeywordFreqIsClicked || (!keywordFreqData && !keywordNetworkData)) {
            console.log("visualizeKeywordFreqIsClicked =", visualizeKeywordFreqIsClicked);
            console.log("keywordFreqData =", keywordFreqData, "keywordNetworkData =", keywordNetworkData);
            return <img src={visImgSample} alt="Visualization Sample" />;
        }
    
        // 데이터가 있는 경우
        if (keywordFreqData && keywordNetworkData) {
            console.log("Data available. Fetching S3 data...");
            console.log("keywordFreqData =", keywordFreqData, "keywordNetworkData =", keywordNetworkData);
    
            // 데이터를 비동기로 로드
            fetchS3Data()
                .then(() => {
                    console.log("fetchS3Data completed successfully");
                    Swal.fire({
                        icon: 'success',
                        title: '📊 시각화 완료!',
                        html: '다운로드 버튼을 누르면 이미지를 받으실 수 있어요.',
                        confirmButtonText: '확인',
                        showLoaderOnConfirm: false,
                        didOpen: () => {
                            Swal.hideLoading();
                        },
                    });
                })
                .catch((error) => {
                    console.error("Error during fetchS3Data:", error);
                    Swal.fire({
                        icon: 'error',
                        title: '데이터 로드 실패',
                        text: '데이터를 불러오는데 실패했습니다. 다시 시도해주세요.',
                    });
                });
    
                return (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start', // 내용이 위쪽에 정렬되도록 설정
                            gap: '10px', // 컴포넌트 간의 간격
                            marginTop: '20px',
                            boxSizing: 'border-box', // 박스 크기 계산 방식 설정
                        }}
                    >
                        {/* 워드클라우드 */}
                        <div id="wordcloud" style={{ flexBasis: '30%', boxSizing: 'border-box' }}>
                            <WordCloud data={wordclouddata} />
                        </div>
                        {/* 키워드 빈도 차트 */}
                        <div id="keywordfrequencychart" style={{ flexBasis: '30%', boxSizing: 'border-box' }}>
                            <KeywordFrequencyChart data={keywordfreqchartdata} />
                        </div>
                        {/* 키워드 연결망 */}
                        <div id="keywordnetwork" style={{ flexBasis: '30%', boxSizing: 'border-box' }}>
                            <KeywordNetwork data={networkdata} />
                        </div>
                    </div>
                );
                
        }
    
        console.log("No matching condition in handleVisualizeKeywordFreq");
    };
    
    

    return (
        <div>
            <div>
            <img src={previewImg} alt="" style={{ width: '100%', height: 'auto' }} />
            </div>
            <br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '7px' }}>
                <h2>🗂️ 분석할 Data 업로드하기</h2>
                <InfoTooltip text="현재 2.5MB 이하의 CSV 파일만 분석 가능해요" />
            </div>
            <br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '3px' }}>
                <div className='file-upload'>
                    <input type="file" id="ex_file" onChange={onFileChange}
                        style={{ display: 'none' }} /> {/* 기본 파일 업로드 input 숨김 */}
                    <div className="button-container" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        {selectedFile && (
                            <h3>
                                <strong>📍 업로드된 파일 : </strong> {selectedFile.name} ({selectedFile.size} bytes)
                            </h3>
                        )}
                        {/* <FileSelectBtn btnName={"파일 선택"}></FileSelectBtn> */}
                        <FileSelectBtn btnName="파일 선택" onClick={() => setKeywordFreqData(false)} />

                        <Button size="sm"
                            onClick={uploadFileS3}>업로드하기</Button>
                    </div>
                </div>
            </div>
            <br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                <h2>🔍 분석할 Data 선택하기</h2>
                {selectColumnTotal()}
                <div>
                    <Button
                        size="sm"
                        // disabled={!selectedColumn}
                        backgroundColor={mainColor}
                        onClick={visualizeTotal}
                    >
                        시각화 시작하기
                    </Button>
                </div>
            </div>
            <br />
            {dataPreviewShow()}
            <br />
            <br /><br />
            <div className='preprocess-grid-container'>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '7px' }}>
                    <h2>✅ (선택) 제거할 단어 추가하기 </h2>
                    <InfoTooltip text="불필요한 단어를 제거할 수 있어요(ex.안녕, ㅋㅋㅋ, 감사)" />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '7px' }}>
                    <h2>✅ (선택) 고유명사 등록하기</h2>
                    <InfoTooltip text="사람이름, 브랜드명 등을 온전히 표시하기 위해 필요해요" />
                </div>
            </div>
            <div className='preprocess-grid-container'>
                <div style={{ maxWidth: '600px', margin: '10', padding: '10px 0' }}>
                    <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '10px', maxHeight: '150px', overflowY: 'auto', marginBottom: '20px' }}>
                        <h3>👇 시각화 시 제거될 단어 리스트</h3>
                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                            {queue1.map((item, index) => (
                                <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#f9f9f9', margin: '5px 0', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}>
                                    <span>{item}</span>
                                    <button onClick={() => handleRemove1(index)} style={{ background: 'none', border: 'none', color: 'red', fontWeight: 'bold', cursor: 'pointer' }}>X</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <input type="text" value={input1} onChange={handleChange1} onKeyPress={handleKeyPress1}
                        placeholder="단어 입력 후 Enter 누르시면 리스트에 단어가 추가돼요" style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc' }} />
                </div>


                <div style={{ maxWidth: '600px', margin: '10', padding: '10px 0' }}>
                    <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '10px', maxHeight: '150px', overflowY: 'auto', marginBottom: '20px' }}>
                        <h3>👇 시각화 시 고려될 고유명사 리스트</h3>
                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                            {queue2.map((item, index) => (
                                <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#f9f9f9', margin: '5px 0', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}>
                                    <span>{item}</span>
                                    <button onClick={() => handleRemove2(index)} style={{ background: 'none', border: 'none', color: 'red', fontWeight: 'bold', cursor: 'pointer' }}>X</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <input type="text" value={input2} onChange={handleChange2} onKeyPress={handleKeyPress2}
                        placeholder="단어 입력 후 Enter 누르시면 리스트에 단어가 추가돼요"
                        style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc' }} />
                </div>
            </div>
            <br />
            <br />
            <br /><br /><br />
            <div>{ShowVisualize()}</div>
            <div>{showErrorImg()}</div>
        </div>
    );
};

ProductViewVisualizer.propTypes = {
    product: PropTypes.object,
};

export default withRouter(ProductViewVisualizer);