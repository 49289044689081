import React, { useContext, useEffect } from 'react';
import { getCurrentDateTime } from '../utils/getCurrentDateTime'; // 현재 시간 읽어오기
import { isMobile, isDesktop } from '../utils/detectDevice'; // 디바이스 타입 읽어오기
import axios from 'axios';
import WriteRDS from './WriteRDS';
import showCurentTime from './showCurrentTime';

const EventTracker = (eventParams) => {
  const { pageName, pageCode, eventName, eventCode, transactionNo } = eventParams;
  //사용자ID
  const storedProfileID = localStorage.getItem('profileID');

  // 방문시간
  const eventTime = showCurentTime();

  // 디바이스 타입 
  let deviceType = "";
  if (isMobile()) deviceType = 'mw';
  else if (isDesktop()) deviceType = 'web';

  // 방문값 누적 적재용 (PK)
  const uniVisitCode = storedProfileID + "_" + pageCode + "_" + eventTime;
  const cateUid = "profile";

  console.log(`uniVisitCode: ${uniVisitCode}`);

  // DB 저장용 배열 세팅
  const values = {
    uni_visit_code: uniVisitCode,
    event_code: eventCode,
    event_name: eventName,
    transaction_no: transactionNo,
    uid: storedProfileID,
    cate_uid: cateUid,
    device_type: deviceType,
    event_time: eventTime,
    page_name: pageName,
    page_code: pageCode,
  }

  console.log("uniVisitCode:", values.uni_visit_code);
  console.log("event_code:", values.event_code);
  console.log("event_name:", values.event_name);
  console.log("transaction_no:", values.transaction_no);
  console.log("page_name:", values.page_name);
  console.log("page_code:", values.page_code);
  console.log("uid:", values.uid);
  console.log("cate_uid:", values.cate_uid);
  console.log("event_time:", values.eventTime);
  console.log("device_type:", values.device_type);
  const tableName = 'tb_event_info';

  return values;
};

export default EventTracker;