import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Papa from "papaparse";

const KeywordFrequencyChart = ({ data }) => {
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const parseData = () => {
            try {
                const parsed = Papa.parse(data, {
                    header: true,
                    skipEmptyLines: true,
                });

                if (parsed.errors.length > 0) {
                    throw new Error(parsed.errors.map((e) => e.message).join(", "));
                }

                const parsedData = parsed.data
                    .map((row) => ({
                        word: row.word,
                        count: parseInt(row.count, 10) || 0,
                    }))
                    .filter((item) => item.word && item.count > 0)
                    .sort((a, b) => b.count - a.count)
                    .slice(0, 10); // 상위 10개 데이터만 사용

                const labels = parsedData.map((d) => d.word);
                const counts = parsedData.map((d) => d.count);

                setChartData({
                    labels,
                    datasets: [
                        {
                            label: "Word Frequency",
                            data: counts,
                            backgroundColor: [
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56",
                                "#4BC0C0",
                                "#9966FF",
                                "#FF9F40",
                                "#E74C3C",
                                "#3498DB",
                                "#2ECC71",
                                "#F1C40F",
                            ],
                            borderColor: "#ccc",
                            borderWidth: 1,
                        },
                    ],
                });
            } catch (err) {
                console.error("데이터 파싱 오류:", err);
                setError("데이터를 처리하는 데 오류가 발생했습니다.");
            }
        };

        if (data) {
            parseData();
        }
    }, [data]);

    if (error) {
        return <p style={{ color: "red", fontWeight: "bold" }}>{error}</p>;
    }

    if (!chartData) {
        return <p style={{ color: "gray", fontStyle: "italic" }}>데이터를 불러오는 중...</p>;
    }

    const options = {
        indexAxis: "y", // 가로 막대 그래프
        responsive: true,
        maintainAspectRatio: false, // 비율 고정 해제
        plugins: {
            legend: {
                display: false,
                position: "top",
            },
            tooltip: {
                callbacks: {
                    label: (context) => `${context.raw} occurrences`,
                },
            },
        },
        animation: {
            duration: 1000,
            easing: "easeOutBounce",
        },
        scales: {
            x: {
                beginAtZero: true,
                grid: {
                    color: "rgba(200,200,200,0.2)",
                },
                title: {
                    display: true,
                    text: "Frequency",
                },
            },
            y: {
                ticks: {
                    autoSkip: false, // Y축 레이블 자동 생략 방지
                    font: {
                        size: 14, // Y축 글자 크기 조정
                    },
                },
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: "Keywords",
                },
            },
        },
        layout: {
            padding: {
                top: 2,
                bottom: 2,
            },
        },
        barThickness: 10, // 막대 두께 설정
    };

    return (
        <div
            style={{
                width: "100%",
                height: "300px", // 화면 높이를 최대한 활용
                padding: "20px",
                boxSizing: "border-box",
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "300px", // 부모 컨테이너 높이를 모두 사용
                }}
            >
                <Bar 
                    data={chartData} 
                    options={options} 
                    style={{
                        width: "100%",
                        height: "300px", // 부모 컨테이너 크기와 맞춤
                    }} 
                />
            </div>
        </div>
    );
    
};

export default KeywordFrequencyChart;
