// ProductViewAI.jsx 및 MyPage.jsx 에서 긍부정 도넛차트 및 시계열 데이터 전처리를 하기 위한 함수

import Papa from 'papaparse';
import dayjs from 'dayjs';

// 날짜 포맷 변환 함수
const parseCustomDate = (dateString) => {
    if (!dateString) return null;
    dateString = dateString.trim();

    const regex = /(\d{4})년\s?(\d{1,2})월\s?(\d{1,2})일/;
    const match = dateString.match(regex);
    if (match) {
        const [, year, month, day] = match;
        return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    }

    const parsedDate = dayjs(dateString);
    return parsedDate.isValid() ? parsedDate.format('YYYY-MM-DD') : null;
};

// CSV 데이터 처리 함수 (공통 유틸 함수)
export const processCSVData = async (csvData, setDoughnutData, setLineData) => {
    return new Promise((resolve, reject) => {
        Papa.parse(csvData, {
            header: true,
            complete: (results) => {
                const data = results.data;
                const sentimentCounts = { positive: 0, negative: 0 };
                const sentimentTrend = {};

                data.forEach(row => {
                    const sentiment = row.sentimental_result?.trim();
                    const dateString = row['댓글작성일'];
                    const parsedDate = parseCustomDate(dateString);

                    if (parsedDate) {
                        const month = dayjs(parsedDate).format('YYYY-MM');

                        if (!sentimentTrend[month]) {
                            sentimentTrend[month] = { positive: 0, negative: 0 };
                        }

                        if (sentiment === '긍정') {
                            sentimentTrend[month].positive++;
                            sentimentCounts.positive++;
                        } else if (sentiment === '부정') {
                            sentimentTrend[month].negative++;
                            sentimentCounts.negative++;
                        }
                    }
                });

                // 도넛 차트 데이터 설정
                const doughnutChartData = {
                    labels: ['Positive', 'Negative'],
                    datasets: [
                        {
                            label: 'Sentiment Analysis',
                            data: [sentimentCounts.positive, sentimentCounts.negative],
                            backgroundColor: ['#36A2EB', '#FF6384'],
                        },
                    ],
                };

                // 월별 트렌드 그래프 데이터 설정
                // const months = Object.keys(sentimentTrend);
                // const positiveData = months.map(month => sentimentTrend[month].positive);
                // const negativeData = months.map(month => sentimentTrend[month].negative);

                const months = Object.keys(sentimentTrend).sort((a, b) => dayjs(a).valueOf() - dayjs(b).valueOf()); 
                const positiveData = months.map(month => sentimentTrend[month].positive);
                const negativeData = months.map(month => sentimentTrend[month].negative);

                const lineChartData = {
                    labels: months,
                    datasets: [
                        {
                            label: 'Positive Trend',
                            data: positiveData,
                            borderColor: '#36A2EB',
                            fill: false,
                        },
                        {
                            label: 'Negative Trend',
                            data: negativeData,
                            borderColor: '#FF6384',
                            fill: false,
                        },
                    ],
                };

                // 상태 업데이트
                setDoughnutData(doughnutChartData);
                setLineData(lineChartData);

                resolve({ sentimentCounts, sentimentTrend, doughnutData: doughnutChartData, lineData: lineChartData });
            },
            error: (error) => {
                console.error('CSV 파싱 에러:', error);
                reject(error);
            },
        });
    });
};