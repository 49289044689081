import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bgcolor } from '../../node_modules/@mui/system/esm/palette/palette';
import palette from '@mui/system/palette';

const Button = (props) => {
    const [isHovered, setIsHovered] = useState(false);
    const size = props.size ? 'btn-' + props.size : '';
    const animate = props.animate ? 'btn-animate' : '';
    // 컬러 설정
    const mainColor = 'rgb(63, 100, 243)'; // 텍스트와 테두리의 기본 색상
    const whiteColor = '#FFFFFF'; // 흰색

    // 마우스 오버에 따른 스타일 설정
    const buttonStyle = {
        backgroundColor: (isHovered || props.highlight === "y") ? mainColor : whiteColor,
        color: (isHovered || props.highlight === "y" ) ? whiteColor : mainColor,
        border: `0.5px solid grey`, // 항상 같은 테두리 색상
        cursor: props.disabled ? 'not-allowed' : 'pointer',
        borderRadius: '12px', // 모서리를 둥글게
        padding: '0.2px 0.2px', // 버튼 패딩 설정
        transition: 'background-color 0.3s ease, color 0.3s ease', // 부드러운 전환 효과
        boxShadow: isHovered 
            ? '4px 4px 12px rgba(63, 100, 243, 0.3)' // 마우스 오버 시 그림자 추가
            : '2px 2px 8px rgba(0, 0, 0, 0.1)', // 기본 상태의 그림자 (살짝 있음)
        fontSize: '15px', // 폰트 사이즈를 명시적으로 설정
        fontFamily: 'SUIT-Regular' // SUIT 글꼴을 기본으로 설정
    };

    return (
        <button
            className={`btn ${size} ${animate}`}
            onClick={props.onClick ? () => props.onClick() : null}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={buttonStyle}
            disabled={props.disabled}
            fontSize={props.fontSize}
        >
            <span className="btn__txt">{props.children}</span>
            {props.icon ? (
                <span className="btn__icon">
                    <i className={`${props.icon} bx-tada`}></i>
                </span>
            ) : null}
        </button>
    );
};

Button.propTypes = {
    backgroundColor: PropTypes.string,
    size: PropTypes.string,
    icon: PropTypes.string,
    animate: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    fontSize : PropTypes.string,
    highlight : PropTypes.string,
};

export default Button;
