import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const KeywordNetwork = ({ data }) => {
    const svgRef = useRef();

    useEffect(() => {
        if (!data || data.length === 0) return;

        // CSV 데이터를 객체 배열로 변환
        const parsedData = data
            .trim()
            .split("\n")
            .slice(1) // 헤더 제거
            .map((row) => {
                const [word1, word2, freq] = row.split(",");
                return { source: word1, target: word2, value: parseInt(freq, 10) };
            })
            .filter((link) => link.source && link.target && !isNaN(link.value)) // 유효한 데이터만 필터링
            .sort((a, b) => b.value - a.value) // 빈도수 기준 정렬
            .slice(0, 200); // 상위 200개 데이터만 사용

        const width = 600;
        const height = 400;

        // SVG 초기화
        const svg = d3.select(svgRef.current);
        svg.selectAll("*").remove(); // 이전 내용 제거
        svg.attr("viewBox", [0, 0, width, height]);

        // 그래프 데이터 생성
        const nodes = Array.from(
            new Set(parsedData.flatMap((link) => [link.source, link.target])),
            (id) => ({ id })
        );
        const links = parsedData;

        // 시뮬레이션 설정
        const simulation = d3
            .forceSimulation(nodes)
            .force("link", d3.forceLink(links).id((d) => d.id).distance(100))
            .force("charge", d3.forceManyBody().strength(-300))
            .force("center", d3.forceCenter(width / 2, height / 2));

        // 링크 그리기
        const link = svg
            .append("g")
            .attr("stroke", "#999")
            .attr("stroke-opacity", 0.6)
            .selectAll("line")
            .data(links)
            .join("line")
            .attr("stroke-width", (d) => Math.sqrt(d.value));

        // 노드 그리기
        const node = svg
            .append("g")
            .attr("stroke", "#fff")
            .attr("stroke-width", 1.5)
            .selectAll("circle")
            .data(nodes)
            .join("circle")
            .attr("r", 10)
            .attr("fill", "steelblue")
            .call(
                d3
                    .drag()
                    .on("start", (event, d) => {
                        if (!event.active) simulation.alphaTarget(0.3).restart();
                        d.fx = d.x;
                        d.fy = d.y;
                    })
                    .on("drag", (event, d) => {
                        d.fx = event.x;
                        d.fy = event.y;
                    })
                    .on("end", (event, d) => {
                        if (!event.active) simulation.alphaTarget(0);
                        d.fx = null;
                        d.fy = null;
                    })
            );

        // 노드 라벨 추가
        const label = svg
            .append("g")
            .selectAll("text")
            .data(nodes)
            .join("text")
            .attr("x", 15)
            .attr("y", 5)
            .text((d) => d.id)
            .style("font-size", "24px")
            .style("fill", "#333");

        // 시뮬레이션 업데이트
        simulation.on("tick", () => {
            link
                .attr("x1", (d) => d.source.x)
                .attr("y1", (d) => d.source.y)
                .attr("x2", (d) => d.target.x)
                .attr("y2", (d) => d.target.y);

            node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);

            label
                .attr("x", (d) => d.x + 10)
                .attr("y", (d) => d.y + 3);
        });

        return () => simulation.stop();
    }, [data]);

    return <svg ref={svgRef} width="100%" height="300px" />;
};

export default KeywordNetwork;
