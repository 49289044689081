import { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import Button from '../components/Button';
import { tossClientKeySecretLive, rdswrite, mainColor, scraperGooglePlayStoreReviewMain, scraperYoutubeCommentMain } from '../config';
import Swal from 'sweetalert2';
import imgSuccess from '../assets/images/check.png';


const PaySuccess = () => {
    const history = useHistory();  // useHistory 훅 초기화
    const location = useLocation();
    const [isKeyLoaded, setIsKeyLoaded] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const productName = queryParams.get("productName");
    const productCode = queryParams.get("productCode");
    const serviceCode = queryParams.get("serviceCode");
    const paymentDate = queryParams.get("paymentDate");
    const profileID = queryParams.get("profileID");
    const transactionNo = queryParams.get("orderId");
    const estimatedCost = queryParams.get("amount");
    const requestCNT = queryParams.get("requestCNT");
    const requestURL = queryParams.get("requestURL");

    const [loadingPayComplete, setLoadingPayComplete] = useState(true);
    const [loadingComplete, setLoadingComplete] = useState(true);

    let scrapMainAPI = '';

    console.log(`orderId=${transactionNo}&amount=${estimatedCost}&profileID=${profileID}&serviceCode=${serviceCode}&productName=${productName}&productCode=${productCode}&paymentDate=${paymentDate}&requestCNT=${requestCNT}&requestURL=${requestURL}`);


    // DB 저장용 배열 세팅
    const items = {
        "transaction_no": transactionNo,
        "service_code": serviceCode,
        "product_name": productName,   // 영상제목 or 앱 이름
        "product_code": productCode,   // 영상/앱 URL 고유 Key 값
        "payment_date": paymentDate,   // 결제일+시간 timestamp
        "payment_amount": estimatedCost, // 결제금액
        "profile_id": profileID,     // 사용자ID
        "request_cnt": requestCNT     // 데이터 수집 요청 건수
    }

    console.log(JSON.stringify(items));

    // RDS에 저장할 컬럼명
    const columns = [
        "transaction_no",
        "service_code",
        "product_name",
        "product_code",
        "payment_date",
        "payment_amount",
        "profile_id",
        "request_cnt"
    ];

    const TableNamePayment = "tb_payment";

    const scrapData = async () => {
        try {
            console.log(`수집 테스트 정상 완료 건!`);

            // 현재 날짜를 '241216' 형태로 포맷팅
            const formatDate = () => {
                const now = new Date();
                const year = String(now.getFullYear()).slice(2); // 연도 마지막 두 자리
                const month = String(now.getMonth() + 1).padStart(2, '0'); // 월 (0부터 시작하므로 +1)
                const day = String(now.getDate()).padStart(2, '0'); // 일
                return `${year}${month}${day}`;
            };

            const temp_requestDate = formatDate();

            const req = {
                "requestURL": requestURL,
                "profileID": profileID,
                "requestCNT": requestCNT,
                "transactionNo": transactionNo,
                "service_code": serviceCode,
                "product_code": productCode,
                "requestDate": temp_requestDate
            };

            console.log(req); // 요청 객체 확인

            console.log(`------req 확인------`);
            console.log(`1. requestURL : ${requestURL}`);
            console.log(`2. profileID : ${profileID}`);
            console.log(`3. requestCNT : ${requestCNT}`);
            console.log(`4. transactionNo : ${transactionNo}`);
            console.log(`5. service_code : ${serviceCode}`);
            console.log(`6. product_code : ${productCode}`);
            console.log(`7. requestDate : ${temp_requestDate}`);
            console.log(`-------------------`);

            console.log(`scrapMainAPI: ${scrapMainAPI}`);

            const response = await axios.post(scrapMainAPI, req,
                {
                    headers: {
                        'Content-Type': 'application/json', // JSON 데이터로 전송
                    },
                })
                .then((res) => {
                    console.log(`main 크롤링 요청 완료!`);
                    console.log(`res : ${res.data}`);
                })
        } catch (error) {
            console.error(`Error scrap / ${error}`);
        }
    }

    // Lambda에 데이터 전송 함수
    const saveDataToRDS = async () => {
        try {
            const response = await axios.post(rdswrite, {
                table_name: TableNamePayment, // 삽입할 테이블 이름
                columns: columns,         // 테이블 컬럼명
                values: [Object.values(items)] // 삽입할 데이터 배열
            });
            console.log(`Data saved to RDS ${TableNamePayment}: ${response.data}`);
        } catch (error) {
            console.error(`Error saving data to RDS ${TableNamePayment}: ${error}`);
        }
    };

    const paymentConfirm = async () => {
        const base64EncodedKey = window.btoa(tossClientKeySecretLive + ':');
        console.log('paysucess 페이지 접근!');
        const requestData = {
            orderId: queryParams.get("orderId"),
            amount: queryParams.get("amount"),
            paymentKey: queryParams.get("paymentKey"),
        };

        await axios.post('https://api.tosspayments.com/v1/payments/confirm', requestData,
            {
                headers: {
                    'Authorization': `Basic ${base64EncodedKey}`,
                    'Content-Type': 'application/json', // JSON 데이터로 전송
                },
            }
        ).then((res) => {
            console.log(`성공 : ${res}`);
            setLoadingComplete(false);
            saveDataToRDS();
            scrapData();
        }).catch((err) => {
            console.log(`에러 : ${err}`);
            setLoadingComplete(false);
            // Swal.fire({
            //     icon: 'warning',
            //     title: '결제가 완료되지 않았어요',
            //     html: '이전 페이지에서 결제 요청을 다시 해주세요.<br/>문제가 지속되면 카톡문의 부탁드립니다 :)',
            //     confirmButtonText: '확인',
            // });
        })
    }



    const formatDateString = (isoString) => {
        const date = new Date(isoString);

        const year = date.getFullYear(); // 연도
        const month = date.getMonth() + 1; // 월 (0부터 시작하므로 +1 필요)
        const day = date.getDate(); // 일

        const hours = date.getHours(); // 시
        const minutes = date.getMinutes(); // 분
        const seconds = date.getSeconds(); // 초

        return `${year}년 ${month}월 ${day}일 ${hours}시 ${minutes}분 ${seconds}초`;
    };


    useEffect(() => {

        if (serviceCode == 'cr_0001') {
            scrapMainAPI = scraperGooglePlayStoreReviewMain;
            console.log(`${serviceCode} / scraperGooglePlayStoreReviewMain / ${scrapMainAPI}`);
        } else if (serviceCode == 'cr_0002') {
            scrapMainAPI = scraperYoutubeCommentMain;
            console.log(`${serviceCode} / scraperYoutubeCommentMain / ${scrapMainAPI}`);
        }

        if (loadingPayComplete) {
            const maxWaitTime = 30000; // 최대 대기 시간
            const minWaitTime = 3000;  // 최소 대기 시간
            const start = Date.now();

            const checkLoadingStatus = () => {
                const elapsed = Date.now() - start;
                if (tossClientKeySecretLive) {
                    setIsKeyLoaded(true);
                    setLoadingPayComplete(false);
                    setLoadingComplete(true);
                    paymentConfirm();
                    scrapData();
                }
                
                else if (elapsed >= maxWaitTime) {
                    loadingPayComplete(false);
                    setLoadingComplete(true);
                } else if (elapsed >= minWaitTime && !loadingComplete) {
                    // False로 변경되면 타이머 즉시 종료
                    setLoadingComplete(true);
                } else {
                    setTimeout(checkLoadingStatus, 100); // 0.1초마다 상태 확인
                }
            };

            checkLoadingStatus();

            return () => clearTimeout(checkLoadingStatus); // 컴포넌트 unmount 시 타이머 정리
        }

        // if (tossClientKeySecretLive) {
        //     setIsKeyLoaded(true);
        // } else {
        //     // Refresh the page if the key is not available
        //     window.location.reload();
        // }

    }, [tossClientKeySecretLive]);


    return (

        <div className="result wrapper" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            fontSize: '1.5em',
        }}>
            <div className="box_section" ali>
                <br /><br /><br />
                {isKeyLoaded ?
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center'  // 텍스트 가운데 정렬
                    }}>
                        <h2>
                            <span style={{
                                color: mainColor,
                            }}>주문이 정상적으로 완료</span>
                            되었습니다.
                        </h2>
                    </div>
                    :
                    <div><h2>결제가 진행 중입니다.</h2></div>}
                <br />
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'  // 텍스트 가운데 정렬
                }}>
                    {isKeyLoaded ?
                        <p>마이페이지에서 데이터 다운과 결제내역을 확인하실 수 있어요 :)</p>
                        :
                        <p>새로고침이나 페이지 이동하지 마시고 1분만 잠시 기다려주세요 :)</p>
                    }

                </div>
                <br />
                <div>
                    <p style={{ fontSize: '16px', color: 'grey' }}>
                        {`📍주문내역: ${queryParams.get("service_code") === 'cr_0001' ? "유튜브 영상 댓글 " : "구글 플레이 스토어 앱 리뷰 "} ${Number(queryParams.get("requestCNT")).toLocaleString()}건 수집`}
                    </p>
                    <p style={{ fontSize: '16px', color: 'grey' }}>{`📍주문번호: ${queryParams.get("orderId")}`}</p>
                    <p style={{ fontSize: '16px', color: 'grey' }}>{`📍주문일시: ${formatDateString(queryParams.get("paymentDate"))}`}</p>
                    <p style={{ fontSize: '16px', color: 'grey' }}>{`📍결제 금액: ${Number(
                        queryParams.get("amount")
                    ).toLocaleString()}원`}</p>
                </div>
                <br />
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'  // 텍스트 가운데 정렬
                }}>
                    {isKeyLoaded ?
                        <Button
                            size="sm"
                            backgroundColor={mainColor}
                            onClick={() => history.push('/mypage')}
                        >
                            마이페이지 이동
                        </Button>
                        : null
                    }
                </div>
            </div>
        </div>
    );
};

export default PaySuccess;
