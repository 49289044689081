import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import Button from '../components/Button'
import Papa from 'papaparse'; // 엑셀 파싱 모듈
import EventTracker from '../components/EventTracker';
import ProgressBarScrap from '../components/ProgressBarScrap'; // 크롤링 진척 확인용
import Swal from 'sweetalert2';

import { UploadFileAPI, GPTcategoryAPI, API_S3_GETOBJ1 } from '../config';
import InfoTooltip from '../components/InfoTooltip'; // 데이터 다운로드 받을 수 있는 곳 안내용

import WriteRDS from '../components/WriteRDS';

import FileSelectBtn from '../components/FileUpload';
import loadingDefault from '../assets/images/loading_default.gif';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const mainColor = 'rgb(70, 100, 166)';
const Preprocess = () => {

    const disabledBtnColor = 'grey';
    const [profileID, setProfileID] = useState('');
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [data, setData] = useState([]);
    const [dataUploadFlag, setDataUploadFlag] = useState(false);
    const [loadingFileUpload, setLoadingFileUpload] = useState(false);
    const [fileUploadBtnColor, setFileUploadBtnColor] = useState(disabledBtnColor);
    const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState(false);
    const [fileValidation, setFileValidation] = useState(false);
    const [transactionNo, setTransactionNo] = useState("-");
    const [selectedColumn, setSelectedColumn] = useState('');
    const [selectThreshold, setSelectThreshold] = useState("최소 신뢰수준 입력");
    const [unixTime, setUnixTime] = useState(Math.floor(Date.now() / 1000));

    const [preprocessLoading, setPreprocessLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const categoryList = ['디자인', '기능', '에러', '포인트', '수수료'];

    const [preprocessComplete, setPreprocessComplete] = useState(false);
    const [preprocessData, setPreprocessData] = useState([]);

    const maxAttempts = 60; // 5분 동안 5초에 1번씩 체크 (60 * 5초 = 300초 = 5분)
    const checkInterval = 5000; // 5000ms = 5초

    const history = useHistory();  // useHistory 훅 초기화

    // State for pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Calculate the rows for the current page
    const rowsToDisplay = preprocessData.slice(
        page * rowsPerPage + 1, // Skip header row
        page * rowsPerPage + rowsPerPage + 1
    );

    let pageName = "preprocess";
    let pageCode = "p0013";
    let eventCode = "";
    let eventName = "";
    let values = [];
    const tableNameEventInfo = 'tb_event_info';

    useEffect(() => {
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');

        if (loadingFileUpload) {
            const maxWaitTime = 30000; // 최대 대기 시간
            const minWaitTime = 5000;  // 최소 대기 시간
            const start = Date.now();

            const checkLoadingStatus = () => {
                const elapsed = Date.now() - start;

                if (elapsed >= maxWaitTime) {
                    setLoadingFileUpload(false);
                    setLoadingComplete(true);
                } else if (elapsed >= minWaitTime && !loadingComplete) {
                    // False로 변경되면 타이머 즉시 종료
                    setLoadingComplete(true);
                } else {
                    setTimeout(checkLoadingStatus, 100); // 0.1초마다 상태 확인
                }
            };

            checkLoadingStatus();

            return () => clearTimeout(checkLoadingStatus); // 컴포넌트 unmount 시 타이머 정리
        }

        if (selectedFile) {
            uploadFileS3();
        }
    }, [profileID, selectedFile]);

    const handleColumnChange = (event) => {
        setSelectedColumn(event.target.value);
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleThresholdChange = (event) => {
        setSelectThreshold(event.target.value);
    };

    const trackEvent = async () => {
        let eventParams = {
            pageName: pageName,
            pageCode: pageCode,
            eventName: eventName,
            eventCode: eventCode,
            transactionNo: transactionNo,
        };

        values = EventTracker(eventParams);

        // WriteRDS 호출로 데이터베이스에 적재
        await WriteRDS(tableNameEventInfo, values);
    };

    const uploadFileS3 = async () => {

        try {
            setLoadingFileUpload(true);

            Swal.fire({
                title: '💨파일 업로드 중',
                html: '최대 10초까지 소요되니 잠시만 기다려주세요.',
                icon: 'info',
                allowOutsideClick: false,
                timer: 10000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading(); // 로딩 애니메이션 시작
                },
            });

            eventName = "upload_file";
            eventCode = "e0014";
            trackEvent();

            if (!profileID) {
                Swal.fire({
                    icon: 'error',
                    title: '🎈로그인 필요!',
                    html: "데이터 시각화는 무료이나 로그인이 필요해요 :)",
                    confirmButtonText: '로그인하러 가기',
                });
                history.push('/login');
            }

            // 파일 미선택 시 경고 팝업
            // if (!fileUploadedSuccessfully) {
            //     Swal.fire({
            //         icon: 'warning',
            //         title: 'csv 파일을 업로드해 주세요',
            //         html: '현재 2.5MB 이하의 파일만 분석할 수 있어요',
            //         confirmButtonText: '확인',
            //         didOpen: () => {
            //             Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
            //         }
            //     });
            //     setLoadingFileUpload(false);
            //     setSelectedFile(null);
            //     return;
            // }

            // 파일 용량 제한 초과 시
            if (selectedFile.size > 2.5e+6) {
                console.log(`업로드 파일 업로드 용량 초과 : ${selectedFile.size}`);
                Swal.fire({
                    icon: 'error',
                    title: '최대 용량 초과',
                    html: '2.5MB 이하의 csv 파일만 업로드해 주세요.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;

            }

            if (selectedFile.size <= 2.5e+6) { }
            else {
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    html: 'csv 파일만 업로드해 주세요.<br/>문제 해결이 안 되면 카톡플러스친구로 문의바랍니다.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;
            }
            const fileType = selectedFile.name.split('.').pop();
            // dataPreviewSetup(fileType);
            const reader = new FileReader();
            const fileExtension = selectedFile.name.split('.').pop();
            console.log(`fileExtension: ${fileExtension}`);
            if (fileExtension != 'csv' && fileExtension != 'xlsx' && fileExtension != 'xls') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: '현재 csv 파일만 업로드 가능합니다.',
                    confirmButtonText: '확인',
                });
                setLoadingFileUpload(false);
                setSelectedFile(null);
                return;
            } else {
                console.log('유효한 파일 형식');

                setFileValidation(true);
                setFileUploadedSuccessfully(true);
                setFileUploadBtnColor(disabledBtnColor);

                // 파일을 읽는 방식을 정의 241006
                if (fileExtension === 'csv') {
                    reader.readAsText(selectedFile, 'UTF-8');  // UTF-8 인코딩으로 읽기
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    reader.readAsArrayBuffer(selectedFile);  // XLSX 파일은 ArrayBuffer로 읽음
                }

                reader.onload = (e) => {
                    const fileData = e.target.result;
                    const generateUniqueHeader = (headers) => {

                        console.log(`-----generateUniqueHeader 함수 실행-----`);
                        console.log(`headers.length : ${headers.length}`);
                        console.log(`headers[0] : ${headers[0]}`);
                        console.log(`type(headers[0]) : ${typeof (headers[0])}`);
                        console.log(`headers[1] : ${headers[1]}`);
                        console.log(`headers 자체 : ${headers}`);
                        // const newHeaders = headers.map(item => typeof(item) === undefined ? "undefinedColumn" : item);
                        const newHeaders = Array.from(headers, item => {
                            if (typeof item === 'undefined') {
                                return "undefinedColumn";
                            } else {
                                return item;
                            }
                        })

                        const uniqueHeaders = [];
                        const headerCount = {};
                        console.log(`newHeaders[0] : ${newHeaders[0]}`);
                        console.log(`type(newHeaders[0]) : ${typeof (newHeaders[0])}`);
                        console.log(`newHeaders 자체 : ${newHeaders}`);

                        newHeaders.forEach((header, index) => {
                            console.log(`현재 header : ${header}`);

                            if (header === "" || header === "undefined") {
                                console.log(`현재 header 비어있거나 undefinde : ${header}`);
                                header = "undefinedColumn";
                            }

                            if (uniqueHeaders.includes(header)) {
                                let count = headerCount[header] || 1;
                                let newHeader = `${header}${count}`;

                                while (uniqueHeaders.includes(newHeader)) {
                                    count += 1;
                                    newHeader = `${header}${count}`;
                                }

                                uniqueHeaders.push(newHeader);
                                headerCount[header] = count + 1;
                            } else {
                                uniqueHeaders.push(header);
                                headerCount[header] = 1;
                            }
                        });

                        return uniqueHeaders;
                    };

                    if (fileExtension === 'csv') {
                        Papa.parse(fileData, {
                            header: true,
                            complete: (results) => {
                                const csvData = results.data;
                                console.log(`csvData: ${csvData}`);
                                let headers = Object.keys(csvData[0]);
                                console.log(`csvData[1]: ${Object.keys(csvData[1])}`);
                                headers = generateUniqueHeader(headers);
                                console.log(`headers: ${headers}`);
                                console.log(`headers type: ${typeof (headers)}`);
                                // 처음 5개의 행만 선택
                                const rows = csvData.slice(0, 5).map(Object.values);
                                console.log(`rows: ${rows}`);
                                setData([headers, ...rows]);
                            },
                        });
                    }
                    else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                        const workbook = XLSX.read(fileData, { type: 'binary' });
                        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                        let headers = jsonData[0];
                        console.log(`jsonData[0]: ${jsonData[0]}`);

                        headers = generateUniqueHeader(headers);
                        jsonData[0] = headers;
                        // 헤더의 일부 셀이 비어 있거나 "undefined"면 unique header 생성
                        if (jsonData.length > 0) {

                        }
                        setData(jsonData.slice(0, 5));
                    }
                };

                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('profileID', profileID);

                try {
                    const response = await axios.post(UploadFileAPI, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((res) => {
                        console.log('File uploaded successfully:', res.data);
                        setFileUploadedSuccessfully(true);
                        Swal.fire({
                            icon: 'success',
                            title: '파일 업로드 성공',
                            text: `이제 분석할 컬럼을 선택해 주세요`,
                            confirmButtonText: '확인',
                            didOpen: () => {
                                Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                            }
                        });
                        setDataUploadFlag(true);
                        setLoadingFileUpload(false);
                    });

                } catch (error) {
                    console.error('Error uploading file:', error);
                    Swal.fire({
                        icon: 'error',
                        title: '👀파일 상태를 확인해 주세요',
                        html: '파일에 빈 행이 있거나 컬럼명이 없지 않은지 확인해 주세요 :)<br/>계속 문제 해결이 안 되면 카톡문의 부탁드려요',
                        confirmButtonText: '확인',
                        didOpen: () => {
                            Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                        }
                    });
                    setDataUploadFlag(false);
                    setLoadingFileUpload(false);
                    setData([]);
                    setSelectedFile("");
                    setFileValidation(false);
                    setFileUploadedSuccessfully(false);
                }

            }
        } catch (error) {
            console.log(`파일 업로드 중 에러 발생 : ${error}`);
            Swal.fire({
                icon: 'error',
                title: '👀파일 상태를 확인해 주세요',
                html: '파일에 빈 행이 있거나 컬럼명이 없지 않은지 확인해 주세요 :)<br/>계속 문제 해결이 안 되면 카톡문의 부탁드려요',
                confirmButtonText: '확인',
                didOpen: () => {
                    Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                }
            });
            setDataUploadFlag(false)
            setLoadingFileUpload(false);
            setFileValidation(false);
            setFileUploadedSuccessfully(false);
            setData([]);
            setSelectedFile("");
        }
    }


    const onFileChange = (event) => {
        try {
            console.log(`event.target.files[0]: ${event.target.files[0]}`);
            setSelectedFile(event.target.files[0]);
            const reader = new FileReader();
            const fileExtension = event.target.files[0].name.split('.').pop();
            console.log(`fileExtension: ${fileExtension}`);
            // if (fileExtension != 'csv' && fileExtension != 'xlsx' && fileExtension != 'xls') {
            if (fileExtension != 'csv') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: 'csv 파일만 업로드 가능합니다. 변환후 업로드 해 주세요.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setSelectedFile(null);
                return;
            } else {
                // 유효한 파일 확인 case
                setFileUploadBtnColor(mainColor);
                setFileUploadedSuccessfully(true);
                // dataPreviewSetup(fileExtension);
            };

        } catch (err) {
            console.log(`error 발생: ${err}`);
        }
    };

    const calculateColumnWidths = () => {
        if (preprocessData.length == 0) {
            if (data.length === 0) return [];
            const columnWidths = data[0].map((_, colIndex) => {
                const maxLength = Math.max(
                    ...data.map((row) => (row[colIndex] ? row[colIndex].toString().length : 0))
                );
                return maxLength * 2; // Adjust the multiplier as needed
            });
            return columnWidths;
        }

        else {
            if (preprocessData.length === 0) return [];
            const columnWidths = preprocessData[0].map((_, colIndex) => {
                const maxLength = Math.max(
                    ...preprocessData.map((row) => (row[colIndex] ? row[colIndex].toString().length : 0))
                );
                return maxLength * 2; // Adjust the multiplier as needed
            });
            return columnWidths;
        }

    };

    const dataPreviewSetup = (fileExtension) => {
        try {
            const reader = new FileReader();
            console.log(`fileExtension: ${fileExtension}`);
            if (fileExtension != 'csv') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: 'csv 파일만 업로드 가능합니다. 변환후 업로드 해 주세요.',
                    confirmButtonText: '확인',
                    didOpen: () => {
                        Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                    }
                });
                setSelectedFile(null);
                return;
            } else {
                console.log('유효한 파일 형식');
                setFileValidation(true);
                setFileUploadedSuccessfully(true);
                setFileUploadBtnColor(disabledBtnColor);

                // 파일을 읽는 방식을 정의 위치 수정 241006
                if (fileExtension === 'csv') {
                    reader.readAsText(selectedFile, 'UTF-8');  // UTF-8 인코딩으로 읽기
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    reader.readAsArrayBuffer(selectedFile);  // XLSX 파일은 ArrayBuffer로 읽음
                }

                reader.onload = (e) => {
                    const fileData = e.target.result;
                    if (fileExtension === 'csv') {
                        Papa.parse(fileData, {
                            header: true,
                            complete: (results) => {
                                const csvData = results.data;
                                console.log(`csvData: ${csvData}`);
                                const headers = Object.keys(csvData[0]);
                                console.log(`headers: ${headers}`);
                                console.log(`headers type: ${typeof (headers)}`);
                                const rows = csvData.map(Object.values);
                                console.log(`rows: ${rows}`);
                                setData([headers, ...rows]);
                            },
                        });
                    } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                        const workbook = XLSX.read(fileData, { type: 'binary' });
                        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                            header: 1

                        });
                        setData(jsonData.slice(0, 10));

                    }
                };
                if (fileExtension === 'csv') {
                    console.log("fileExtension === 'csv'");
                    reader.readAsText(selectedFile);
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    console.log("fileExtension === 'xlsx' || fileExtension === 'xls'");
                    reader.readAsBinaryString(selectedFile);
                }

            }
        }
        catch (error) {
            setDataUploadFlag(false);
            setLoadingFileUpload(false);
            setData([]);
            setSelectedFile("");
            setFileValidation(false);
            setFileUploadedSuccessfully(false);
            Swal.fire({
                icon: 'error',
                title: '👀파일 상태를 확인해 주세요',
                html: '파일에 빈 행이 있거나 컬럼명이 없지 않은지 확인해 주세요 :)<br/>계속 문제 해결이 안 되면 카톡문의 부탁드려요',
                confirmButtonText: '확인',
                didOpen: () => {
                    Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                }
            });

        }

    }

    const PaginatedTable = ({ data }) => {
        const [page, setPage] = useState(1); // Page numbers start from 1
        const rowsPerPage = 10; // Fixed rows per page
    
        // Calculate the total number of pages
        const totalPages = Math.max(1, Math.ceil((data.length - 1) / rowsPerPage)); // Exclude header row and ensure at least 1 page
    
        // Find the column indices for "댓글" and "관련문장"
        const commentColIndex = data[0]?.indexOf(selectedColumn);
        const topicColIndex = data[0]?.indexOf('토푁');
        const relatedSentenceColIndex = data[0]?.indexOf("관련문장");
    
        // Handle page change
        const handleChangePage = (event, value) => {
            setPage(value);
        };
    
        // Calculate rows to display based on current page
        const rowsToDisplay = data.slice(
            (page - 1) * rowsPerPage + 1, // Skip header row
            Math.min((page - 1) * rowsPerPage + rowsPerPage + 1, data.length) // Ensure not exceeding data length
        );
    
        return (
            <div>
                <TableContainer component={Paper} style={{ maxHeight: '60vh', overflowY: 'auto', width: '100%' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {data[0]?.map((header, index) => (
                                    <TableCell
                                        key={index}
                                        // style={{
                                        //     position: 'sticky',
                                        //     top: 0,
                                        //     backgroundColor: 'lightgrey',
                                        //     zIndex: 2,
                                        //     fontWeight: 'bold',
                                        //     textAlign: 'center',
                                        //     minWidth: `${header.length * 10 + 70}px`, // Minimum width based on header length
                                        // }}
                                        style={{
                                            position: 'sticky',
                                            backgroundColor: 'lightgrey',
                                            zIndex: 2,
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}

                                    >
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowsToDisplay.map((row, rowIndex) => {
                                const relatedSentences = row[relatedSentenceColIndex]
                                    ?.split(/[\,\?]/) // Split by commas or question marks
                                    .map((s) => s.trim()); // Trim whitespace
                                return (
                                    <TableRow key={rowIndex}>
                                        {row.map((cell, cellIndex) => (
                                            <TableCell key={cellIndex}>
                                                {/* Check if it's the "댓글" column and highlight matching text */}
                                                {cellIndex === commentColIndex && relatedSentences ? (
                                                    <span>
                                                        {relatedSentences.reduce((acc, sentence) => {
                                                            if (typeof acc !== "string") {
                                                                acc = String(cell);
                                                            }
                                                            return acc.split(sentence).flatMap((part, idx, arr) =>
                                                                idx < arr.length - 1 ? (
                                                                    <React.Fragment key={`${sentence}-${idx}`}>
                                                                        {part}
                                                                        <span
                                                                            style={{
                                                                                color: 'red',
                                                                                fontWeight: 'bold',
                                                                            }}
                                                                        >
                                                                            {sentence}
                                                                        </span>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    part
                                                                )
                                                            );
                                                        }, String(cell))}
                                                    </span>
                                                ) : (
                                                    cell
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Custom Pagination */}
                <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: '10px' }}
                >
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        siblingCount={2}
                        boundaryCount={1}
                        showFirstButton
                        showLastButton
                        color="primary"
                    />
                </Stack>
            </div>
        );
    };

    const downloadResult = () => {
        if (!preprocessData || preprocessData.length === 0) {
            Swal.fire({
                icon: 'error',
                title: '데이터가 없습니다!',
                text: '먼저 데이터를 로드한 후 다시 시도하세요.',
            });
            return;
        }
        const utf8BOM = '\uFEFF'; // UTF-8 BOM
        // 데이터 내 개행 처리 및 CSV 직렬화 (데이터에 개행이 있으면 쌍따옴표로 감싸기)
        const escapeCSVValue = (value) => {
            if (typeof value === 'string' && (value.includes(',') || value.includes('\n') || value.includes('"'))) {
                // 쌍따옴표를 두 개로 변환하고, 전체를 쌍따옴표로 감싸기
                return `"${value.replace(/"/g, '""')}"`;
            }
            return value;
        };
    
        const csvContent = utf8BOM + [
            preprocessData[0].map(escapeCSVValue).join(','),  // 헤더 처리
            ...preprocessData.slice(1).map(row => row.map(escapeCSVValue).join(',')) // 데이터 행 처리
        ].join('\n');
    
        // Blob을 생성하고 다운로드 트리거
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${selectedFile.name.replace('.csv', '')}_processed.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        Swal.fire({
            icon: 'success',
            title: '✅ 다운로드 완료!',
            confirmButtonText: '확인'
        });
    };


    
    // /*****************html로 다운받기(빨간색 강조 포함) ****************/
    // const downloadResult = () => { 
    //     if (!preprocessData || preprocessData.length === 0) {
    //         Swal.fire({
    //             icon: 'error',
    //             title: '데이터가 없습니다!',
    //             text: '먼저 데이터를 로드한 후 다시 시도하세요.',
    //         });
    //         return;
    //     }
    
    //     const relatedSentenceColIndex = preprocessData[0].indexOf("관련문장");
    //     const commentColIndex = preprocessData[0].indexOf(selectedColumn);
    
    //     const escapeHTML = (str) => str.replace(/</g, "&lt;").replace(/>/g, "&gt;");
        
    //     // 데이터 내 하이라이팅을 위한 함수
    //     const highlightMatchingText = (cell, relatedSentences) => {
    //         if (!relatedSentences) return escapeHTML(cell);
            
    //         return relatedSentences.reduce((acc, sentence) => {
    //             if (typeof acc !== "string") acc = String(cell);
    //             return acc.split(sentence).flatMap((part, idx, arr) =>
    //                 idx < arr.length - 1 ? (
    //                     `${part}<span style="color: red; font-weight: bold;">${sentence}</span>`
    //                 ) : part
    //             ).join('');
    //         }, escapeHTML(cell));
    //     };
    
    //     // HTML 테이블 생성
    //     const htmlContent = `
    //         <html>
    //         <head><meta charset="UTF-8"><title>Processed Data</title></head>
    //         <body>
    //             <table border="1" cellpadding="5" cellspacing="0" style="border-collapse: collapse; text-align: center;">
    //                 <thead>
    //                     <tr>${preprocessData[0].map(header => `<th>${escapeHTML(header)}</th>`).join('')}</tr>
    //                 </thead>
    //                 <tbody>
    //                     ${preprocessData.slice(1).map(row => {
    //                         const relatedSentences = row[relatedSentenceColIndex]
    //                             ?.split(/[,\?]/)
    //                             .map(s => s.trim());
    //                         return `
    //                         <tr>
    //                             ${row.map((cell, cellIndex) =>
    //                                 `<td>${
    //                                     cellIndex === commentColIndex 
    //                                         ? highlightMatchingText(cell, relatedSentences) 
    //                                         : escapeHTML(cell)
    //                                 }</td>`
    //                             ).join('')}
    //                         </tr>`;
    //                     }).join('')}
    //                 </tbody>
    //             </table>
    //         </body>
    //         </html>
    //     `;
    
    //     // Blob을 생성하고 다운로드 트리거 (HTML 파일로)
    //     const blob = new Blob([htmlContent], { type: 'text/html;charset=utf-8;' });
    //     const url = URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', `${selectedFile.name.replace('.csv', '')}_processed.html`);
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
        
    //     Swal.fire({
    //         icon: 'success',
    //         title: '✅ 다운로드 완료!',
    //         confirmButtonText: '확인'
    //     });
    // };    





    // /***************엑셀로 다운받기***************** */
    // const downloadResult = () => {
    //     if (!preprocessData || preprocessData.length === 0) {
    //         Swal.fire({
    //             icon: 'error',
    //             title: '데이터가 없습니다!',
    //             text: '먼저 데이터를 로드한 후 다시 시도하세요.',
    //         });
    //         return;
    //     }

    //     const relatedSentenceColIndex = preprocessData[0].indexOf("관련문장");
    //     const commentColIndex = preprocessData[0].indexOf(selectedColumn);

    //     // Excel 워크북과 워크시트 생성
    //     const workbook = XLSX.utils.book_new();
    //     const worksheet = XLSX.utils.aoa_to_sheet(preprocessData);

    //     // 빨간색 강조를 위한 스타일 정의
    //     const highlightStyle = {
    //         font: { color: { rgb: "FF0000" }, bold: true } // 빨간색 글자
    //     };

    //     // 데이터 순회하며 관련 문장 강조
    //     preprocessData.slice(1).forEach((row, rowIndex) => {
    //         const relatedSentences = row[relatedSentenceColIndex]?.split(/[,\?]/).map(s => s.trim());

    //         row.forEach((cell, cellIndex) => {
    //             if (cellIndex === commentColIndex && relatedSentences) {
    //                 relatedSentences.forEach(sentence => {
    //                     if (cell.includes(sentence)) {
    //                         // 해당 셀 강조
    //                         const address = XLSX.utils.encode_cell({ r: rowIndex + 1, c: cellIndex });
    //                         if (!worksheet[address]) worksheet[address] = { t: 's', v: cell };
    //                         worksheet[address].s = highlightStyle;
    //                     }
    //                 });
    //             }
    //         });
    //     });

    //     // 워크북에 스타일 적용
    //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Processed Data');

    //     // Blob으로 파일 생성 및 다운로드 트리거
    //     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //     const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    //     saveAs(blob, `${selectedFile.name.replace('.csv', '')}_processed.xlsx`);

    //     Swal.fire({
    //         icon: 'success',
    //         title: '✅ 엑셀 다운로드 완료!',
    //         confirmButtonText: '확인'
    //     });
    // };

    const resetResult = () => {
        Swal.fire({
            icon: 'info',
            title: '하이라이팅 결과를 초기화합니다',
        });
        setPreprocessData([]);
        setSelectedCategory('');
        setSelectedColumn('');
        setPreprocessComplete(false);
    }


    const dataPreviewShow = () => {
        const columnWidths = calculateColumnWidths();
        if (loadingFileUpload & !dataUploadFlag) {
            Swal.fire({
                title: '엑셀 업로드 중',
                html: "새로고침하지 마시고 잠시만 기다려주세요.<br/>최대 10초까지 소요될 수 있어요 :)",
                icon: 'info',
                timer: 5000,
                allowOutsideClick: false, // 팝업 밖을 클릭해도 닫히지 않음
                allowEscapeKey: false,    // ESC 키로 닫을 수 없게 설정
                allowEnterKey: false,     // 엔터 키로 닫을 수 없게 설정
                timerProgressBar: true,   // 타이머 진행 상황 표시
                didOpen: () => {
                    Swal.showLoading();     // 팝업에 로딩 애니메이션 표시
                },
                willClose: () => {
                    Swal.fire({
                        title: `엑셀 업로드 완료!`,
                        icon: 'success',
                        confirmButtonText: '확인',
                        confirmButtonColor: mainColor,
                    })
                }
            })
        }

        else if (!loadingFileUpload && dataUploadFlag) {
            return (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '20px',
                            justifyContent: 'center',
                            padding: '10px',
                        }}
                    >

                        {preprocessData.length > 0
                            ?
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '7px' }}>
                                <Button size="sm" backgroundColor={mainColor} onClick={downloadResult}>
                                    결과 다운로드(.csv)
                                </Button>
                                <Button size="sm" backgroundColor={'rgb(215, 78, 114)'} onClick={resetResult}>
                                    초기화하기
                                </Button>
                            </div>
                            :
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '7px' }}>
                                <select
                                    value={selectedColumn}
                                    onChange={handleColumnChange}
                                    style={{
                                        padding: '10px',
                                        fontSize: '14px',
                                        borderRadius: '12px',
                                        color: mainColor,
                                        cursor: 'pointer',
                                        border: `0.5px solid grey`,
                                        transition: 'background-color 0.3s ease, color 0.3s ease',
                                    }}
                                >
                                    <option value="" disabled>
                                        ❗️[필수] 텍스트 분석할 열 선택{' '}
                                    </option>
                                    {data[0].map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <input
                                    type="text"
                                    id="selectCategory"
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    placeholder={" 어떤 토픽의 데이터를 찾아볼까요? (ex. 기능, 디자인, 에러)"}
                                    style={{
                                        width: '400px',
                                        padding: '10px',
                                        fontSize: '14px',
                                        borderRadius: '12px',
                                        color: mainColor,
                                        cursor: 'pointer',
                                        border: `0.5px solid grey`,
                                        transition: 'background-color 0.3s ease, color 0.3s ease',
                                    }}
                                />

                                <Button size="sm" backgroundColor={mainColor} onClick={requestPreprocess}>
                                    분석 시작하기
                                </Button>
                            </div>

                        }

                    </div>

                    <br />

                    {preprocessData.length > 0 ? (

                        <PaginatedTable data={preprocessData} />
                    ) : (
                        <PaginatedTable data={data} />
                    )}
                </div>
            );
        }


        else if (!loadingFileUpload) {
            return (

                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '3px' }}>
                    <div className='file-upload'>
                        <input type="file" id="ex_file" onChange={onFileChange}
                            style={{ display: 'none' }} /> {/* 기본 파일 업로드 input 숨김 */}
                        <div className="button-container" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <FileSelectBtn btnName={"엑셀 업로드하기(.csv)"}></FileSelectBtn>
                        </div>
                        <br/><br/>
                        <p style={{ fontSize: '1.5rem', color: 'gray' }}>
                            결과 예시 : 
                        </p>
                        <br/>
                        <img 
                            src={require('../assets/images/preprocess.JPG')} 
                            alt="결과 예시" 
                            style={{ maxWidth: '70%', border: '1px solid #ccc', borderRadius: '15px' }}
                        />
                    </div>
                </div>
            )
        }

    };

    const requestPreprocess = async () => {
        if (!selectedColumn) {
            Swal.fire({
                icon: 'error',
                title: '분석 컬럼 선택 필요',
                html: "분석할 컬럼을 먼저 선택해 주세요",
                confirmButtonText: '확인',
            });
            return;
        }

        if (!selectedCategory) {
            Swal.fire({
                icon: 'error',
                title: '토픽 선정 필요',
                html: "찾으실 데이터의 토픽을 먼저 입력해 주세요",
                confirmButtonText: '확인',
            });
            return;
        }
        setPreprocessLoading(true);
        console.log(`전처리 시작`);

        const req = {
            "user_id": profileID,
            "filename": selectedFile.name.replace('.csv', ''),
            "content_column": selectedColumn,
            "category": selectedCategory,
            "random_number": unixTime,
        }

        console.log(`selectedCategory : ${selectedCategory} `);

        Swal.fire({
            title: `👀 '${selectedCategory}' 데이터 선별하는 중`,
            html: '최대 30초까지 소요되니 잠시만 기다려주세요.',
            icon: 'info',
            allowOutsideClick: true,
            timer: 30000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading(); // 로딩 애니메이션 시작
            },

            // willClose: () => {
            //     Swal.fire({
            //         title: `👻유의미한 데이터를 찾아냈어요!`,
            //         icon: 'success',
            //         confirmButtonText: '확인',
            //         confirmButtonColor: mainColor,
            //     })
            // }
        });

        try {
            const res = await axios.post(GPTcategoryAPI, req, {
                headers: {
                    'Content-Type': 'application/json', // JSON 데이터 전송
                },
            });

            if (res.status === 200 && res.data) {  // 요청이 성공했을 때만 실행
                console.log(`res : ${res.data}`);
                getPreprocessedResult();
                return;
            }

        } catch (error) {

            console.log(`전처리 중 에러 발생 : ${error}`);
            Swal.fire({
                title: '오류 발생',
                text: '전처리 중 오류가 발생했습니다.',
                icon: 'error',
                confirmButtonText: '확인',
            });
        }


    }

    const decodeUnicode = (str) => {
        try {
            return str.replace(/\\u[\dA-F]{4}/gi, (match) => {
                return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
            });

        } catch (error) {
            console.log(`decodeUnicode Error : ${error}`);
            return;
        }

    };

    const getPreprocessedResult = async () => {
        if (!preprocessComplete) {
            console.log(`결과 엑셀 파일 불러오기 시작`);
            let attempts = 0;
            let loadFileName = `${selectedFile.name.replace('.csv', '')}`;
            const req = {
                "profileid": profileID.toString(),
                "serviceCode": "pr_0001",
                "productCode": "pr_0001",
                "request_cnt": 1000,
                "fileName": loadFileName,
                "unix_time": unixTime.toString(),
            };

            const makeRequest = async () => {
                attempts++;
                console.log(`${attempts}번째 시도`);

                try {

                    const response = await axios.post(API_S3_GETOBJ1, req, {
                        headers: {
                            'Content-Type': 'application/json', // JSON 데이터로 전송
                        },
                    })
                    console.log(`response.data.statusCode: ${response.data.statusCode}`);
                    console.log(`Object.keys(response.data): ${Object.keys(response.data)}`);
                    // let cleanedData = response.data.body.replace(/^\uFEFF/, ''); // BOM 제거
                    console.log(`response.data.body: ${response.data.body}`);
                    // // let receivedData = JSON.parse(cleanedData);
                    // console.log(`typeof(response.data.body): ${typeof(response.data.body)}`);
                    // const cleanedBody = response.data.body.replace(/^\uFEFF/, ""); // 문자열 시작 부분의 BOM 제거
                    // let receivedData = JSON.parse(cleanedBody);

                    // Papa.parse(response.data.body, {
                    //     header: true, // CSV 첫 줄을 키로 사용
                    //     skipEmptyLines: true, // 빈 줄 건너뛰기
                    //     complete: (result) => {
                    //       console.log("Parsed CSV Data:", result.data);
                    //       setPreprocessData(result.data);
                    //     },
                    //     error: (error) => {
                    //       console.error("Error parsing CSV:", error.message);
                    //     },
                    //   });

                    // Papa.parse(response.data.body, {
                    //     header: false, // 헤더를 자동으로 키로 사용하지 않음
                    //     skipEmptyLines: true, // 빈 줄 건너뛰기
                    //     complete: (result) => {
                    //       const rawData = result.data;

                    //       // 헤더와 데이터 분리
                    //       const headers = rawData[0]; // 첫 번째 줄을 헤더로 저장
                    //       const rows = rawData.slice(1); // 나머지 줄은 데이터로 저장

                    //       console.log("Headers:", headers);
                    //       console.log("Data Rows:", rows);

                    //       // 헤더를 키로 사용해 객체로 변환 (선택)
                    //       const formattedData = rows.map((row) =>
                    //         headers.reduce((acc, header, index) => {
                    //           acc[header] = row[index];
                    //           return acc;
                    //         }, {})
                    //       );

                    //       console.log("Formatted Data:", formattedData);
                    //       setPreprocessData(formattedData); // 최종 데이터 설정
                    //     },
                    //     error: (error) => {
                    //       console.error("Error parsing CSV:", error.message);
                    //     },
                    //   });

                    if (response.data.statusCode === 200) {
                        console.log(`상태코드 200`);
                        Papa.parse(response.data.body, {
                            header: true,
                            complete: (results) => {
                                const csvData = results.data;
                                console.log(`csvData: ${csvData}`);
                                const headers = Object.keys(csvData[0]);
                                console.log(`headers: ${headers}`);
                                console.log(`headers type: ${typeof (headers)}`);
                                const rows = csvData.map(Object.values);
                                console.log(`rows: ${rows}`);
                                setPreprocessData([headers, ...rows]);
                                Swal.close(); // SweetAlert 팝업 닫기
                            },
                        });

                        setLoadingComplete(false);
                        setPreprocessComplete(true);
                        if (response.data.body) {
                            Swal.fire({
                                icon: 'success',
                                title: '✅ 하이라이팅 완료!',
                                html: `'${selectedCategory}' 일치율과 하이라이팅 문장을 확인해보세요!`,
                                confirmButtonText: '확인',
                                showLoaderOnConfirm: false,  // 로딩 애니메이션 표시하지 않음
                                didOpen: () => {
                                    Swal.hideLoading(); // 혹시 이전에 로딩이 있었다면 명시적으로 로딩 숨기기
                                }
                            });
                        }
                        return; // 최대 시도 횟수에 도달하면 재귀 호출을 멈춤
                    }

                    // 최대 시도 횟수 도달 시 처리
                    if (attempts >= maxAttempts) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: '5분 동안 파일을 찾을 수 없습니다!',
                        });
                        return; // 최대 시도 횟수에 도달하면 재귀 호출을 멈춤
                    }

                } catch (error) {
                    console.error('API 호출 중 오류 발생:', error);
                    setPreprocessLoading(false);
                    setPreprocessComplete(false);
                    setPreprocessData([]);
                }

                // 요청 완료 후 5초 후에 다시 요청
                setTimeout(makeRequest, checkInterval);
            };

            // 첫 번째 요청 시작
            makeRequest();



        }
    }


    return (
        <div>
            <div style={{
                padding: '30px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start', // 위에서 시작하도록 설정
                textAlign: 'center',
                height: '100vh', // 화면 전체 높이
                // paddingTop: '30vh', // 위에서 30% 높이에 배치
                margin: 0, // 여백 제거
            }}>
                <h1>
                    Text 데이터 분석 시 꼭 읽어봐야 할
                    <br />
                    데이터와 문장들만 쏙쏙 찾아드려요!
                </h1>
                <br />
                <h3 style={{ color: 'grey' }}>더이상 모든 원문을 일일이 읽어보며 소중한 시간을 낭비하지 마세요🙏</h3>
                <br />
                {dataPreviewShow()}
            </div>
            <div>

            </div>
        </div>

    );
};

export default Preprocess;